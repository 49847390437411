// Setting colors

$main: #108477;
$mainText: #049989;
$green: #18A957;

$dark1: #161A20;
$dark2: #0E1014;
$dark3: #13161C;
$dark4: #18222F;
$dark5: #32363C;
$dark6: #121519;

$light1: #E5E5E5;
$light2: #DDDDDD;
$light3: #F1F1F1;
$light4: #F2F9FC;
$light5: #D0D1D2;
$light6: #EEEEEE;
$light7: #E4E5E8;
$light8: #E8F6EE;

$white: #FFFFFF;

$gray1: #A1A2A4;
$gray2: #B4B4B4;
$gray3: #767B80;
$gray4: #D5D6D7;
$gray5: #9B9B9B;
$gray6: #848484;
$gray7: #282A30;

$tableHead: #1A1C80;