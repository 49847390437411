@import "https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap";
.iti {
  display: inline-block;
  position: relative;
}

.iti * {
  box-sizing: border-box;
}

.iti__hide {
  display: none;
}

.iti__v-hide {
  visibility: hidden;
}

.iti input, .iti input[type="tel"], .iti input[type="text"] {
  z-index: 0;
  margin-right: 0;
  padding-right: 36px;
  position: relative;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.iti__flag-container {
  padding: 1px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.iti__selected-flag {
  z-index: 1;
  height: 100%;
  align-items: center;
  padding: 0 6px 0 8px;
  display: flex;
  position: relative;
}

.iti__arrow {
  width: 0;
  height: 0;
  border-top: 4px solid #555;
  border-left: 3px solid #0000;
  border-right: 3px solid #0000;
  margin-left: 6px;
}

.iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #555;
}

.iti__country-list {
  z-index: 2;
  text-align: left;
  white-space: nowrap;
  max-height: 200px;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  border: 1px solid #ccc;
  margin: 0 0 0 -1px;
  padding: 0;
  list-style: none;
  position: absolute;
  overflow-y: scroll;
  box-shadow: 1px 1px 4px #0003;
}

.iti__country-list--dropup {
  margin-bottom: -1px;
  bottom: 100%;
}

@media (max-width: 500px) {
  .iti__country-list {
    white-space: normal;
  }
}

.iti__flag-box {
  width: 20px;
  display: inline-block;
}

.iti__divider {
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.iti__country {
  outline: 0;
  padding: 5px 10px;
}

.iti__dial-code {
  color: #999;
}

.iti__country.iti__highlight {
  background-color: #0000000d;
}

.iti__country-name, .iti__dial-code, .iti__flag-box {
  vertical-align: middle;
}

.iti__country-name, .iti__flag-box {
  margin-right: 6px;
}

.iti--allow-dropdown input, .iti--allow-dropdown input[type="tel"], .iti--allow-dropdown input[type="text"], .iti--separate-dial-code input, .iti--separate-dial-code input[type="tel"], .iti--separate-dial-code input[type="text"] {
  margin-left: 0;
  padding-left: 52px;
  padding-right: 6px;
}

.iti--allow-dropdown .iti__flag-container, .iti--separate-dial-code .iti__flag-container {
  left: 0;
  right: auto;
}

.iti--allow-dropdown .iti__flag-container:hover {
  cursor: pointer;
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: #0000000d;
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover, .iti--allow-dropdown input[readonly] + .iti__flag-container:hover {
  cursor: default;
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover .iti__selected-flag, .iti--allow-dropdown input[readonly] + .iti__flag-container:hover .iti__selected-flag {
  background-color: #0000;
}

.iti--separate-dial-code .iti__selected-flag {
  background-color: #0000000d;
}

.iti--separate-dial-code .iti__selected-dial-code {
  margin-left: 6px;
}

.iti--container {
  z-index: 1060;
  padding: 1px;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.iti--container:hover {
  cursor: pointer;
}

.iti-mobile .iti--container {
  position: fixed;
  inset: 30px;
}

.iti-mobile .iti__country-list {
  max-height: 100%;
  width: 100%;
}

.iti-mobile .iti__country {
  padding: 10px;
  line-height: 1.5em;
}

.iti__flag {
  width: 20px;
}

.iti__flag.iti__be {
  width: 18px;
}

.iti__flag.iti__ch {
  width: 15px;
}

.iti__flag.iti__mc {
  width: 19px;
}

.iti__flag.iti__ne {
  width: 18px;
}

.iti__flag.iti__np {
  width: 13px;
}

.iti__flag.iti__va {
  width: 15px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-size: 5652px 15px;
  }
}

.iti__flag.iti__ac {
  height: 10px;
  background-position: 0 0;
}

.iti__flag.iti__ad {
  height: 14px;
  background-position: -22px 0;
}

.iti__flag.iti__ae {
  height: 10px;
  background-position: -44px 0;
}

.iti__flag.iti__af {
  height: 14px;
  background-position: -66px 0;
}

.iti__flag.iti__ag {
  height: 14px;
  background-position: -88px 0;
}

.iti__flag.iti__ai {
  height: 10px;
  background-position: -110px 0;
}

.iti__flag.iti__al {
  height: 15px;
  background-position: -132px 0;
}

.iti__flag.iti__am {
  height: 10px;
  background-position: -154px 0;
}

.iti__flag.iti__ao {
  height: 14px;
  background-position: -176px 0;
}

.iti__flag.iti__aq {
  height: 14px;
  background-position: -198px 0;
}

.iti__flag.iti__ar {
  height: 13px;
  background-position: -220px 0;
}

.iti__flag.iti__as {
  height: 10px;
  background-position: -242px 0;
}

.iti__flag.iti__at {
  height: 14px;
  background-position: -264px 0;
}

.iti__flag.iti__au {
  height: 10px;
  background-position: -286px 0;
}

.iti__flag.iti__aw {
  height: 14px;
  background-position: -308px 0;
}

.iti__flag.iti__ax {
  height: 13px;
  background-position: -330px 0;
}

.iti__flag.iti__az {
  height: 10px;
  background-position: -352px 0;
}

.iti__flag.iti__ba {
  height: 10px;
  background-position: -374px 0;
}

.iti__flag.iti__bb {
  height: 14px;
  background-position: -396px 0;
}

.iti__flag.iti__bd {
  height: 12px;
  background-position: -418px 0;
}

.iti__flag.iti__be {
  height: 15px;
  background-position: -440px 0;
}

.iti__flag.iti__bf {
  height: 14px;
  background-position: -460px 0;
}

.iti__flag.iti__bg {
  height: 12px;
  background-position: -482px 0;
}

.iti__flag.iti__bh {
  height: 12px;
  background-position: -504px 0;
}

.iti__flag.iti__bi {
  height: 12px;
  background-position: -526px 0;
}

.iti__flag.iti__bj {
  height: 14px;
  background-position: -548px 0;
}

.iti__flag.iti__bl {
  height: 14px;
  background-position: -570px 0;
}

.iti__flag.iti__bm {
  height: 10px;
  background-position: -592px 0;
}

.iti__flag.iti__bn {
  height: 10px;
  background-position: -614px 0;
}

.iti__flag.iti__bo {
  height: 14px;
  background-position: -636px 0;
}

.iti__flag.iti__bq {
  height: 14px;
  background-position: -658px 0;
}

.iti__flag.iti__br {
  height: 14px;
  background-position: -680px 0;
}

.iti__flag.iti__bs {
  height: 10px;
  background-position: -702px 0;
}

.iti__flag.iti__bt {
  height: 14px;
  background-position: -724px 0;
}

.iti__flag.iti__bv {
  height: 15px;
  background-position: -746px 0;
}

.iti__flag.iti__bw {
  height: 14px;
  background-position: -768px 0;
}

.iti__flag.iti__by {
  height: 10px;
  background-position: -790px 0;
}

.iti__flag.iti__bz {
  height: 14px;
  background-position: -812px 0;
}

.iti__flag.iti__ca {
  height: 10px;
  background-position: -834px 0;
}

.iti__flag.iti__cc {
  height: 10px;
  background-position: -856px 0;
}

.iti__flag.iti__cd {
  height: 15px;
  background-position: -878px 0;
}

.iti__flag.iti__cf {
  height: 14px;
  background-position: -900px 0;
}

.iti__flag.iti__cg {
  height: 14px;
  background-position: -922px 0;
}

.iti__flag.iti__ch {
  height: 15px;
  background-position: -944px 0;
}

.iti__flag.iti__ci {
  height: 14px;
  background-position: -961px 0;
}

.iti__flag.iti__ck {
  height: 10px;
  background-position: -983px 0;
}

.iti__flag.iti__cl {
  height: 14px;
  background-position: -1005px 0;
}

.iti__flag.iti__cm {
  height: 14px;
  background-position: -1027px 0;
}

.iti__flag.iti__cn {
  height: 14px;
  background-position: -1049px 0;
}

.iti__flag.iti__co {
  height: 14px;
  background-position: -1071px 0;
}

.iti__flag.iti__cp {
  height: 14px;
  background-position: -1093px 0;
}

.iti__flag.iti__cr {
  height: 12px;
  background-position: -1115px 0;
}

.iti__flag.iti__cu {
  height: 10px;
  background-position: -1137px 0;
}

.iti__flag.iti__cv {
  height: 12px;
  background-position: -1159px 0;
}

.iti__flag.iti__cw {
  height: 14px;
  background-position: -1181px 0;
}

.iti__flag.iti__cx {
  height: 10px;
  background-position: -1203px 0;
}

.iti__flag.iti__cy {
  height: 14px;
  background-position: -1225px 0;
}

.iti__flag.iti__cz {
  height: 14px;
  background-position: -1247px 0;
}

.iti__flag.iti__de {
  height: 12px;
  background-position: -1269px 0;
}

.iti__flag.iti__dg {
  height: 10px;
  background-position: -1291px 0;
}

.iti__flag.iti__dj {
  height: 14px;
  background-position: -1313px 0;
}

.iti__flag.iti__dk {
  height: 15px;
  background-position: -1335px 0;
}

.iti__flag.iti__dm {
  height: 10px;
  background-position: -1357px 0;
}

.iti__flag.iti__do {
  height: 14px;
  background-position: -1379px 0;
}

.iti__flag.iti__dz {
  height: 14px;
  background-position: -1401px 0;
}

.iti__flag.iti__ea {
  height: 14px;
  background-position: -1423px 0;
}

.iti__flag.iti__ec {
  height: 14px;
  background-position: -1445px 0;
}

.iti__flag.iti__ee {
  height: 13px;
  background-position: -1467px 0;
}

.iti__flag.iti__eg {
  height: 14px;
  background-position: -1489px 0;
}

.iti__flag.iti__eh {
  height: 10px;
  background-position: -1511px 0;
}

.iti__flag.iti__er {
  height: 10px;
  background-position: -1533px 0;
}

.iti__flag.iti__es {
  height: 14px;
  background-position: -1555px 0;
}

.iti__flag.iti__et {
  height: 10px;
  background-position: -1577px 0;
}

.iti__flag.iti__eu {
  height: 14px;
  background-position: -1599px 0;
}

.iti__flag.iti__fi {
  height: 12px;
  background-position: -1621px 0;
}

.iti__flag.iti__fj {
  height: 10px;
  background-position: -1643px 0;
}

.iti__flag.iti__fk {
  height: 10px;
  background-position: -1665px 0;
}

.iti__flag.iti__fm {
  height: 11px;
  background-position: -1687px 0;
}

.iti__flag.iti__fo {
  height: 15px;
  background-position: -1709px 0;
}

.iti__flag.iti__fr {
  height: 14px;
  background-position: -1731px 0;
}

.iti__flag.iti__ga {
  height: 15px;
  background-position: -1753px 0;
}

.iti__flag.iti__gb {
  height: 10px;
  background-position: -1775px 0;
}

.iti__flag.iti__gd {
  height: 12px;
  background-position: -1797px 0;
}

.iti__flag.iti__ge {
  height: 14px;
  background-position: -1819px 0;
}

.iti__flag.iti__gf {
  height: 14px;
  background-position: -1841px 0;
}

.iti__flag.iti__gg {
  height: 14px;
  background-position: -1863px 0;
}

.iti__flag.iti__gh {
  height: 14px;
  background-position: -1885px 0;
}

.iti__flag.iti__gi {
  height: 10px;
  background-position: -1907px 0;
}

.iti__flag.iti__gl {
  height: 14px;
  background-position: -1929px 0;
}

.iti__flag.iti__gm {
  height: 14px;
  background-position: -1951px 0;
}

.iti__flag.iti__gn {
  height: 14px;
  background-position: -1973px 0;
}

.iti__flag.iti__gp {
  height: 14px;
  background-position: -1995px 0;
}

.iti__flag.iti__gq {
  height: 14px;
  background-position: -2017px 0;
}

.iti__flag.iti__gr {
  height: 14px;
  background-position: -2039px 0;
}

.iti__flag.iti__gs {
  height: 10px;
  background-position: -2061px 0;
}

.iti__flag.iti__gt {
  height: 13px;
  background-position: -2083px 0;
}

.iti__flag.iti__gu {
  height: 11px;
  background-position: -2105px 0;
}

.iti__flag.iti__gw {
  height: 10px;
  background-position: -2127px 0;
}

.iti__flag.iti__gy {
  height: 12px;
  background-position: -2149px 0;
}

.iti__flag.iti__hk {
  height: 14px;
  background-position: -2171px 0;
}

.iti__flag.iti__hm {
  height: 10px;
  background-position: -2193px 0;
}

.iti__flag.iti__hn {
  height: 10px;
  background-position: -2215px 0;
}

.iti__flag.iti__hr {
  height: 10px;
  background-position: -2237px 0;
}

.iti__flag.iti__ht {
  height: 12px;
  background-position: -2259px 0;
}

.iti__flag.iti__hu {
  height: 10px;
  background-position: -2281px 0;
}

.iti__flag.iti__ic {
  height: 14px;
  background-position: -2303px 0;
}

.iti__flag.iti__id {
  height: 14px;
  background-position: -2325px 0;
}

.iti__flag.iti__ie {
  height: 10px;
  background-position: -2347px 0;
}

.iti__flag.iti__il {
  height: 15px;
  background-position: -2369px 0;
}

.iti__flag.iti__im {
  height: 10px;
  background-position: -2391px 0;
}

.iti__flag.iti__in {
  height: 14px;
  background-position: -2413px 0;
}

.iti__flag.iti__io {
  height: 10px;
  background-position: -2435px 0;
}

.iti__flag.iti__iq {
  height: 14px;
  background-position: -2457px 0;
}

.iti__flag.iti__ir {
  height: 12px;
  background-position: -2479px 0;
}

.iti__flag.iti__is {
  height: 15px;
  background-position: -2501px 0;
}

.iti__flag.iti__it {
  height: 14px;
  background-position: -2523px 0;
}

.iti__flag.iti__je {
  height: 12px;
  background-position: -2545px 0;
}

.iti__flag.iti__jm {
  height: 10px;
  background-position: -2567px 0;
}

.iti__flag.iti__jo {
  height: 10px;
  background-position: -2589px 0;
}

.iti__flag.iti__jp {
  height: 14px;
  background-position: -2611px 0;
}

.iti__flag.iti__ke {
  height: 14px;
  background-position: -2633px 0;
}

.iti__flag.iti__kg {
  height: 12px;
  background-position: -2655px 0;
}

.iti__flag.iti__kh {
  height: 13px;
  background-position: -2677px 0;
}

.iti__flag.iti__ki {
  height: 10px;
  background-position: -2699px 0;
}

.iti__flag.iti__km {
  height: 12px;
  background-position: -2721px 0;
}

.iti__flag.iti__kn {
  height: 14px;
  background-position: -2743px 0;
}

.iti__flag.iti__kp {
  height: 10px;
  background-position: -2765px 0;
}

.iti__flag.iti__kr {
  height: 14px;
  background-position: -2787px 0;
}

.iti__flag.iti__kw {
  height: 10px;
  background-position: -2809px 0;
}

.iti__flag.iti__ky {
  height: 10px;
  background-position: -2831px 0;
}

.iti__flag.iti__kz {
  height: 10px;
  background-position: -2853px 0;
}

.iti__flag.iti__la {
  height: 14px;
  background-position: -2875px 0;
}

.iti__flag.iti__lb {
  height: 14px;
  background-position: -2897px 0;
}

.iti__flag.iti__lc {
  height: 10px;
  background-position: -2919px 0;
}

.iti__flag.iti__li {
  height: 12px;
  background-position: -2941px 0;
}

.iti__flag.iti__lk {
  height: 10px;
  background-position: -2963px 0;
}

.iti__flag.iti__lr {
  height: 11px;
  background-position: -2985px 0;
}

.iti__flag.iti__ls {
  height: 14px;
  background-position: -3007px 0;
}

.iti__flag.iti__lt {
  height: 12px;
  background-position: -3029px 0;
}

.iti__flag.iti__lu {
  height: 12px;
  background-position: -3051px 0;
}

.iti__flag.iti__lv {
  height: 10px;
  background-position: -3073px 0;
}

.iti__flag.iti__ly {
  height: 10px;
  background-position: -3095px 0;
}

.iti__flag.iti__ma {
  height: 14px;
  background-position: -3117px 0;
}

.iti__flag.iti__mc {
  height: 15px;
  background-position: -3139px 0;
}

.iti__flag.iti__md {
  height: 10px;
  background-position: -3160px 0;
}

.iti__flag.iti__me {
  height: 10px;
  background-position: -3182px 0;
}

.iti__flag.iti__mf {
  height: 14px;
  background-position: -3204px 0;
}

.iti__flag.iti__mg {
  height: 14px;
  background-position: -3226px 0;
}

.iti__flag.iti__mh {
  height: 11px;
  background-position: -3248px 0;
}

.iti__flag.iti__mk {
  height: 10px;
  background-position: -3270px 0;
}

.iti__flag.iti__ml {
  height: 14px;
  background-position: -3292px 0;
}

.iti__flag.iti__mm {
  height: 14px;
  background-position: -3314px 0;
}

.iti__flag.iti__mn {
  height: 10px;
  background-position: -3336px 0;
}

.iti__flag.iti__mo {
  height: 14px;
  background-position: -3358px 0;
}

.iti__flag.iti__mp {
  height: 10px;
  background-position: -3380px 0;
}

.iti__flag.iti__mq {
  height: 14px;
  background-position: -3402px 0;
}

.iti__flag.iti__mr {
  height: 14px;
  background-position: -3424px 0;
}

.iti__flag.iti__ms {
  height: 10px;
  background-position: -3446px 0;
}

.iti__flag.iti__mt {
  height: 14px;
  background-position: -3468px 0;
}

.iti__flag.iti__mu {
  height: 14px;
  background-position: -3490px 0;
}

.iti__flag.iti__mv {
  height: 14px;
  background-position: -3512px 0;
}

.iti__flag.iti__mw {
  height: 14px;
  background-position: -3534px 0;
}

.iti__flag.iti__mx {
  height: 12px;
  background-position: -3556px 0;
}

.iti__flag.iti__my {
  height: 10px;
  background-position: -3578px 0;
}

.iti__flag.iti__mz {
  height: 14px;
  background-position: -3600px 0;
}

.iti__flag.iti__na {
  height: 14px;
  background-position: -3622px 0;
}

.iti__flag.iti__nc {
  height: 10px;
  background-position: -3644px 0;
}

.iti__flag.iti__ne {
  height: 15px;
  background-position: -3666px 0;
}

.iti__flag.iti__nf {
  height: 10px;
  background-position: -3686px 0;
}

.iti__flag.iti__ng {
  height: 10px;
  background-position: -3708px 0;
}

.iti__flag.iti__ni {
  height: 12px;
  background-position: -3730px 0;
}

.iti__flag.iti__nl {
  height: 14px;
  background-position: -3752px 0;
}

.iti__flag.iti__no {
  height: 15px;
  background-position: -3774px 0;
}

.iti__flag.iti__np {
  height: 15px;
  background-position: -3796px 0;
}

.iti__flag.iti__nr {
  height: 10px;
  background-position: -3811px 0;
}

.iti__flag.iti__nu {
  height: 10px;
  background-position: -3833px 0;
}

.iti__flag.iti__nz {
  height: 10px;
  background-position: -3855px 0;
}

.iti__flag.iti__om {
  height: 10px;
  background-position: -3877px 0;
}

.iti__flag.iti__pa {
  height: 14px;
  background-position: -3899px 0;
}

.iti__flag.iti__pe {
  height: 14px;
  background-position: -3921px 0;
}

.iti__flag.iti__pf {
  height: 14px;
  background-position: -3943px 0;
}

.iti__flag.iti__pg {
  height: 15px;
  background-position: -3965px 0;
}

.iti__flag.iti__ph {
  height: 10px;
  background-position: -3987px 0;
}

.iti__flag.iti__pk {
  height: 14px;
  background-position: -4009px 0;
}

.iti__flag.iti__pl {
  height: 13px;
  background-position: -4031px 0;
}

.iti__flag.iti__pm {
  height: 14px;
  background-position: -4053px 0;
}

.iti__flag.iti__pn {
  height: 10px;
  background-position: -4075px 0;
}

.iti__flag.iti__pr {
  height: 14px;
  background-position: -4097px 0;
}

.iti__flag.iti__ps {
  height: 10px;
  background-position: -4119px 0;
}

.iti__flag.iti__pt {
  height: 14px;
  background-position: -4141px 0;
}

.iti__flag.iti__pw {
  height: 13px;
  background-position: -4163px 0;
}

.iti__flag.iti__py {
  height: 11px;
  background-position: -4185px 0;
}

.iti__flag.iti__qa {
  height: 8px;
  background-position: -4207px 0;
}

.iti__flag.iti__re {
  height: 14px;
  background-position: -4229px 0;
}

.iti__flag.iti__ro {
  height: 14px;
  background-position: -4251px 0;
}

.iti__flag.iti__rs {
  height: 14px;
  background-position: -4273px 0;
}

.iti__flag.iti__ru {
  height: 14px;
  background-position: -4295px 0;
}

.iti__flag.iti__rw {
  height: 14px;
  background-position: -4317px 0;
}

.iti__flag.iti__sa {
  height: 14px;
  background-position: -4339px 0;
}

.iti__flag.iti__sb {
  height: 10px;
  background-position: -4361px 0;
}

.iti__flag.iti__sc {
  height: 10px;
  background-position: -4383px 0;
}

.iti__flag.iti__sd {
  height: 10px;
  background-position: -4405px 0;
}

.iti__flag.iti__se {
  height: 13px;
  background-position: -4427px 0;
}

.iti__flag.iti__sg {
  height: 14px;
  background-position: -4449px 0;
}

.iti__flag.iti__sh {
  height: 10px;
  background-position: -4471px 0;
}

.iti__flag.iti__si {
  height: 10px;
  background-position: -4493px 0;
}

.iti__flag.iti__sj {
  height: 15px;
  background-position: -4515px 0;
}

.iti__flag.iti__sk {
  height: 14px;
  background-position: -4537px 0;
}

.iti__flag.iti__sl {
  height: 14px;
  background-position: -4559px 0;
}

.iti__flag.iti__sm {
  height: 15px;
  background-position: -4581px 0;
}

.iti__flag.iti__sn {
  height: 14px;
  background-position: -4603px 0;
}

.iti__flag.iti__so {
  height: 14px;
  background-position: -4625px 0;
}

.iti__flag.iti__sr {
  height: 14px;
  background-position: -4647px 0;
}

.iti__flag.iti__ss {
  height: 10px;
  background-position: -4669px 0;
}

.iti__flag.iti__st {
  height: 10px;
  background-position: -4691px 0;
}

.iti__flag.iti__sv {
  height: 12px;
  background-position: -4713px 0;
}

.iti__flag.iti__sx {
  height: 14px;
  background-position: -4735px 0;
}

.iti__flag.iti__sy {
  height: 14px;
  background-position: -4757px 0;
}

.iti__flag.iti__sz {
  height: 14px;
  background-position: -4779px 0;
}

.iti__flag.iti__ta {
  height: 10px;
  background-position: -4801px 0;
}

.iti__flag.iti__tc {
  height: 10px;
  background-position: -4823px 0;
}

.iti__flag.iti__td {
  height: 14px;
  background-position: -4845px 0;
}

.iti__flag.iti__tf {
  height: 14px;
  background-position: -4867px 0;
}

.iti__flag.iti__tg {
  height: 13px;
  background-position: -4889px 0;
}

.iti__flag.iti__th {
  height: 14px;
  background-position: -4911px 0;
}

.iti__flag.iti__tj {
  height: 10px;
  background-position: -4933px 0;
}

.iti__flag.iti__tk {
  height: 10px;
  background-position: -4955px 0;
}

.iti__flag.iti__tl {
  height: 10px;
  background-position: -4977px 0;
}

.iti__flag.iti__tm {
  height: 14px;
  background-position: -4999px 0;
}

.iti__flag.iti__tn {
  height: 14px;
  background-position: -5021px 0;
}

.iti__flag.iti__to {
  height: 10px;
  background-position: -5043px 0;
}

.iti__flag.iti__tr {
  height: 14px;
  background-position: -5065px 0;
}

.iti__flag.iti__tt {
  height: 12px;
  background-position: -5087px 0;
}

.iti__flag.iti__tv {
  height: 10px;
  background-position: -5109px 0;
}

.iti__flag.iti__tw {
  height: 14px;
  background-position: -5131px 0;
}

.iti__flag.iti__tz {
  height: 14px;
  background-position: -5153px 0;
}

.iti__flag.iti__ua {
  height: 14px;
  background-position: -5175px 0;
}

.iti__flag.iti__ug {
  height: 14px;
  background-position: -5197px 0;
}

.iti__flag.iti__um {
  height: 11px;
  background-position: -5219px 0;
}

.iti__flag.iti__un {
  height: 14px;
  background-position: -5241px 0;
}

.iti__flag.iti__us {
  height: 11px;
  background-position: -5263px 0;
}

.iti__flag.iti__uy {
  height: 14px;
  background-position: -5285px 0;
}

.iti__flag.iti__uz {
  height: 10px;
  background-position: -5307px 0;
}

.iti__flag.iti__va {
  height: 15px;
  background-position: -5329px 0;
}

.iti__flag.iti__vc {
  height: 14px;
  background-position: -5346px 0;
}

.iti__flag.iti__ve {
  height: 14px;
  background-position: -5368px 0;
}

.iti__flag.iti__vg {
  height: 10px;
  background-position: -5390px 0;
}

.iti__flag.iti__vi {
  height: 14px;
  background-position: -5412px 0;
}

.iti__flag.iti__vn {
  height: 14px;
  background-position: -5434px 0;
}

.iti__flag.iti__vu {
  height: 12px;
  background-position: -5456px 0;
}

.iti__flag.iti__wf {
  height: 14px;
  background-position: -5478px 0;
}

.iti__flag.iti__ws {
  height: 10px;
  background-position: -5500px 0;
}

.iti__flag.iti__xk {
  height: 15px;
  background-position: -5522px 0;
}

.iti__flag.iti__ye {
  height: 14px;
  background-position: -5544px 0;
}

.iti__flag.iti__yt {
  height: 14px;
  background-position: -5566px 0;
}

.iti__flag.iti__za {
  height: 14px;
  background-position: -5588px 0;
}

.iti__flag.iti__zm {
  height: 14px;
  background-position: -5610px 0;
}

.iti__flag.iti__zw {
  height: 10px;
  background-position: -5632px 0;
}

.iti__flag {
  height: 15px;
  background-color: #dbdbdb;
  background-image: url("flags.c61229ae.png");
  background-position: 20px 0;
  background-repeat: no-repeat;
  box-shadow: 0 0 1px #888;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("flags@2x.adefcad5.png");
  }
}

.iti__flag.iti__np {
  background-color: #0000;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  height: auto;
  border: 1px solid #0000;
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-loading .slick-list {
  background: #fff url("ajax-loader.32c19c5c.gif") center no-repeat;
}

@font-face {
  font-family: slick;
  font-weight: normal;
  font-style: normal;
  src: url("slick.019ff462.eot");
  src: url("slick.019ff462.eot#iefix") format("embedded-opentype"), url("slick.c8d05265.woff") format("woff"), url("slick.441ccdd6.ttf") format("truetype"), url("slick.0ba9c062.svg#slick") format("svg");
}

.slick-prev, .slick-next {
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: #0000;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  font-size: 0;
  line-height: 0;
  display: block;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
  color: #0000;
  background: none;
  outline: none;
}

.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
  opacity: .25;
}

.slick-prev:before, .slick-next:before {
  opacity: .75;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: slick;
  font-size: 20px;
  line-height: 1;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  position: absolute;
  bottom: -25px;
}

.slick-dots li {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin: 0 5px;
  padding: 0;
  display: inline-block;
  position: relative;
}

.slick-dots li button {
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: #0000;
  background: none;
  border: 0;
  outline: none;
  padding: 5px;
  font-size: 0;
  line-height: 0;
  display: block;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: .25;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: slick;
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: #000;
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #049989;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #fff;
  --bs-dark: #161a20;
  --bs-primary-rgb: 4, 153, 137;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 255, 255, 255;
  --bs-dark-rgb: 22, 26, 32;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 22, 26, 32;
  --bs-body-bg-rgb: 242, 242, 242;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #161a20;
  --bs-body-bg: #f2f2f2;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  border: 0;
  border-top: 1px solid;
  margin: 1rem 0;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .small {
  font-size: .875em;
}

mark, .mark {
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}

a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  border: 1px solid var(--bs-border-color);
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: .375rem;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: #6c757d;
  font-size: .875em;
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  width: auto;
  flex: none;
}

.row-cols-1 > * {
  width: 100%;
  flex: none;
}

.row-cols-2 > * {
  width: 50%;
  flex: none;
}

.row-cols-3 > * {
  width: 33.3333%;
  flex: none;
}

.row-cols-4 > * {
  width: 25%;
  flex: none;
}

.row-cols-5 > * {
  width: 20%;
  flex: none;
}

.row-cols-6 > * {
  width: 16.6667%;
  flex: none;
}

.col-auto {
  width: auto;
  flex: none;
}

.col-1 {
  width: 8.33333%;
  flex: none;
}

.col-2 {
  width: 16.6667%;
  flex: none;
}

.col-3 {
  width: 25%;
  flex: none;
}

.col-4 {
  width: 33.3333%;
  flex: none;
}

.col-5 {
  width: 41.6667%;
  flex: none;
}

.col-6 {
  width: 50%;
  flex: none;
}

.col-7 {
  width: 58.3333%;
  flex: none;
}

.col-8 {
  width: 66.6667%;
  flex: none;
}

.col-9 {
  width: 75%;
  flex: none;
}

.col-10 {
  width: 83.3333%;
  flex: none;
}

.col-11 {
  width: 91.6667%;
  flex: none;
}

.col-12 {
  width: 100%;
  flex: none;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --bs-gutter-x: 0;
}

.g-0, .gy-0 {
  --bs-gutter-y: 0;
}

.g-1, .gx-1 {
  --bs-gutter-x: .25rem;
}

.g-1, .gy-1 {
  --bs-gutter-y: .25rem;
}

.g-2, .gx-2 {
  --bs-gutter-x: .5rem;
}

.g-2, .gy-2 {
  --bs-gutter-y: .5rem;
}

.g-3, .gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3, .gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4, .gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4, .gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5, .gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5, .gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-sm-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-sm-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-sm-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-sm-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-sm-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-sm-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-sm-auto {
    width: auto;
    flex: none;
  }

  .col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-sm-3 {
    width: 25%;
    flex: none;
  }

  .col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-sm-6 {
    width: 50%;
    flex: none;
  }

  .col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-sm-9 {
    width: 75%;
    flex: none;
  }

  .col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-sm-12 {
    width: 100%;
    flex: none;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  .g-sm-1, .gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  .g-sm-2, .gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  .g-sm-2, .gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  .g-sm-3, .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3, .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4, .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4, .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5, .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5, .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-md-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-md-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-md-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-md-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-md-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-md-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-md-auto {
    width: auto;
    flex: none;
  }

  .col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-md-3 {
    width: 25%;
    flex: none;
  }

  .col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-md-6 {
    width: 50%;
    flex: none;
  }

  .col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-md-9 {
    width: 75%;
    flex: none;
  }

  .col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-md-12 {
    width: 100%;
    flex: none;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  .g-md-1, .gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  .g-md-2, .gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  .g-md-2, .gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  .g-md-3, .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3, .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4, .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4, .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5, .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5, .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-lg-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-lg-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-lg-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-lg-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-lg-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-lg-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-lg-auto {
    width: auto;
    flex: none;
  }

  .col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-lg-3 {
    width: 25%;
    flex: none;
  }

  .col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-lg-6 {
    width: 50%;
    flex: none;
  }

  .col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-lg-9 {
    width: 75%;
    flex: none;
  }

  .col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-lg-12 {
    width: 100%;
    flex: none;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  .g-lg-1, .gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  .g-lg-2, .gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  .g-lg-2, .gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  .g-lg-3, .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3, .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4, .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4, .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5, .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5, .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-xl-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-xl-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-xl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-xl-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-xl-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-xl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-xl-auto {
    width: auto;
    flex: none;
  }

  .col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xl-3 {
    width: 25%;
    flex: none;
  }

  .col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xl-6 {
    width: 50%;
    flex: none;
  }

  .col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xl-9 {
    width: 75%;
    flex: none;
  }

  .col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xl-12 {
    width: 100%;
    flex: none;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xl-1, .gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xl-2, .gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xl-2, .gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xl-3, .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3, .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4, .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4, .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5, .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5, .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-xxl-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-xxl-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-xxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-xxl-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-xxl-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-xxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-xxl-auto {
    width: auto;
    flex: none;
  }

  .col-xxl-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xxl-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xxl-3 {
    width: 25%;
    flex: none;
  }

  .col-xxl-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xxl-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xxl-6 {
    width: 50%;
    flex: none;
  }

  .col-xxl-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xxl-9 {
    width: 75%;
    flex: none;
  }

  .col-xxl-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xxl-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xxl-12 {
    width: 100%;
    flex: none;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .g-xxl-0, .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0, .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1, .gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xxl-1, .gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xxl-2, .gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xxl-2, .gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xxl-3, .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3, .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4, .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4, .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5, .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5, .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: #0000000d;
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: #0000001a;
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: #00000013;
  width: 100%;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  margin-bottom: 1rem;
}

.table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-bottom-width: 1px;
  padding: .5rem;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: .25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(2n+1) > *, .table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #bacbe6;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.form-label {
  margin-bottom: .5rem;
}

.col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
}

.form-text {
  color: #6c757d;
  margin-top: .25rem;
  font-size: .875em;
}

.form-control {
  width: 100%;
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled {
  opacity: 1;
  background-color: #e9ecef;
}

.form-control::file-selector-button {
  margin: -.375rem -.75rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e9ecef;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + .75rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border-radius: .375rem;
  border: 0 !important;
}

.form-control-color::-webkit-color-swatch {
  border-radius: .375rem;
}

.form-control-color.form-control-sm {
  height: calc(1.5em + .5rem + 2px);
}

.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.form-select {
  width: 100%;
  -moz-padding-start: calc(.75rem - 3px);
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.form-select:disabled {
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #212529;
}

.form-select-sm {
  border-radius: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.form-select-lg {
  border-radius: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  vertical-align: top;
  appearance: none;
  print-color-adjust: exact;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #00000040;
  margin-top: .25em;
}

.form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  border-color: #0d6efd;
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: .5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.form-switch.form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.form-switch.form-check-reverse .form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #0d6efd40;
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #0d6efd40;
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating > label {
  width: 100%;
  height: 100%;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: 0 0;
  border: 1px solid #0000;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control, .form-floating > .form-control-plaintext {
  padding: 1rem .75rem;
}

.form-floating > .form-control::placeholder, .form-floating > .form-control-plaintext::placeholder {
  color: #0000;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:-webkit-autofill, .form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label, .form-floating > .form-control:-webkit-autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-select, .input-group > .form-floating {
  width: 1%;
  min-width: 0;
  flex: auto;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .form-select:focus, .input-group > .form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text, .input-group-lg > .btn {
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text, .input-group-sm > .btn {
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select, .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control, .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  width: 100%;
  color: #198754;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #198754e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #198754;
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(1.5em + 3.75rem);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 .25rem #19875440;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated .input-group > .form-select:not(:focus):valid, .input-group > .form-select:not(:focus).is-valid, .was-validated .input-group > .form-floating:not(:focus-within):valid, .input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(1.5em + 3.75rem);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 .25rem #dc354540;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated .input-group > .form-select:not(:focus):invalid, .input-group > .form-select:not(:focus).is-invalid, .was-validated .input-group > .form-floating:not(:focus-within):invalid, .input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #161a20;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: .375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #000;
  --bs-btn-bg: #049989;
  --bs-btn-border-color: #049989;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #2aa89b;
  --bs-btn-hover-border-color: #1da395;
  --bs-btn-focus-shadow-rgb: 3, 130, 116;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #36ada1;
  --bs-btn-active-border-color: #1da395;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #049989;
  --bs-btn-disabled-border-color: #049989;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d9d9d9;
  --bs-btn-hover-border-color: #ccc;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ccc;
  --bs-btn-active-border-color: #bfbfbf;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #fff;
  --bs-btn-disabled-border-color: #fff;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #161a20;
  --bs-btn-border-color: #161a20;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #393c41;
  --bs-btn-hover-border-color: #2d3136;
  --bs-btn-focus-shadow-rgb: 57, 60, 65;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #45484d;
  --bs-btn-active-border-color: #2d3136;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #161a20;
  --bs-btn-disabled-border-color: #161a20;
}

.btn-outline-primary {
  --bs-btn-color: #049989;
  --bs-btn-border-color: #049989;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #049989;
  --bs-btn-hover-border-color: #049989;
  --bs-btn-focus-shadow-rgb: 4, 153, 137;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #049989;
  --bs-btn-active-border-color: #049989;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #049989;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #049989;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #fff;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fff;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #161a20;
  --bs-btn-border-color: #161a20;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #161a20;
  --bs-btn-hover-border-color: #161a20;
  --bs-btn-focus-shadow-rgb: 22, 26, 32;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #161a20;
  --bs-btn-active-border-color: #161a20;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #161a20;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #161a20;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 3, 130, 116;
  text-decoration: underline;
}

.btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: .5rem;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: .25rem;
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .5rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: .375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: .5rem;
  --bs-dropdown-box-shadow: 0 .5rem 1rem #00000026;
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: .25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.dropend .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.dropstart .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropstart .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

.dropdown-item {
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: #ffffff26;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  flex: auto;
  position: relative;
}

.btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:focus + .btn, .btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn:hover, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: .375rem;
}

.btn-group > :not(.btn-check:first-child) + .btn, .btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn.dropdown-toggle-split:first-child, .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn, .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after, .dropend .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn, .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: .375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  background: none;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: .375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}

.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  background: none;
  border: 0;
}

.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link, .nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .5rem;
  --bs-navbar-color: #0000008c;
  --bs-navbar-hover-color: #000000b3;
  --bs-navbar-disabled-color: #0000004d;
  --bs-navbar-active-color: #000000e6;
  --bs-navbar-brand-padding-y: .3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #000000e6;
  --bs-navbar-brand-hover-color: #000000e6;
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: #0000001a;
  --bs-navbar-toggler-border-radius: .375rem;
  --bs-navbar-toggler-focus-width: .25rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .show > .nav-link, .navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-text a, .navbar-text a:hover, .navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  transform: none !important;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: #ffffff8c;
  --bs-navbar-hover-color: #ffffffbf;
  --bs-navbar-disabled-color: #ffffff40;
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: #ffffff1a;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: .5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: .375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(.375rem - 1px);
  --bs-card-cap-padding-y: .5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #00000008;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: .75rem;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

.card > .list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.card-img, .card-img-top, .card-img-bottom {
  width: 100%;
}

.card-img, .card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: .375rem;
  --bs-accordion-inner-border-radius: calc(.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 .25rem #0d6efd40;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #0c63e4;
  --bs-accordion-active-bg: #e7f1ff;
}

.accordion-button {
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:after {
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: .5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
}

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: .75rem;
  --bs-pagination-padding-y: .375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: .375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 .25rem #0d6efd40;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  outline: 0;
}

.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: .75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: .5rem;
}

.pagination-sm {
  --bs-pagination-padding-x: .5rem;
  --bs-pagination-padding-y: .25rem;
  --bs-pagination-font-size: .875rem;
  --bs-pagination-border-radius: .25rem;
}

.badge {
  --bs-badge-padding-x: .65em;
  --bs-badge-padding-y: .35em;
  --bs-badge-font-size: .75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: .375rem;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: .375rem;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  --bs-alert-color: #025c52;
  --bs-alert-bg: #cdebe7;
  --bs-alert-border-color: #b4e0dc;
}

.alert-primary .alert-link {
  color: #024a42;
}

.alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}

.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}

.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}

.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}

.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}

.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  --bs-alert-color: #666;
  --bs-alert-bg: white;
  --bs-alert-border-color: white;
}

.alert-light .alert-link {
  color: #525252;
}

.alert-dark {
  --bs-alert-color: #0d1013;
  --bs-alert-bg: #d0d1d2;
  --bs-alert-border-color: #b9babc;
}

.alert-dark .alert-link {
  color: #0a0d0f;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: .75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: .375rem;
  --bs-progress-box-shadow: inset 0 1px 2px #00000013;
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width .6s ease;
  height: var(--bs-progress-height);
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: #00000020;
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: .375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: .5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  border-radius: var(--bs-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.list-group-numbered > .list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  background-color: var(--bs-list-group-action-hover-bg);
  text-decoration: none;
}

.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  text-decoration: none;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #025c52;
  background-color: #cdebe7;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #025c52;
  background-color: #b9d4d0;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #025c52;
  border-color: #025c52;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #666;
  background-color: #fff;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #666;
  background-color: #e6e6e6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #666;
  border-color: #666;
}

.list-group-item-dark {
  color: #0d1013;
  background-color: #d0d1d2;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #0d1013;
  background-color: #bbbcbd;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #0d1013;
  border-color: #0d1013;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: #000;
  opacity: .5;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center / 1em no-repeat;
  border: 0;
  border-radius: .375rem;
  padding: .25em;
}

.btn-close:hover {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

.btn-close:focus {
  opacity: 1;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25;
}

.btn-close-white {
  filter: invert() grayscale() brightness(200%);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: .75rem;
  --bs-toast-padding-y: .5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: .875rem;
  --bs-toast-color: ;
  --bs-toast-bg: #ffffffd9;
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: .375rem;
  --bs-toast-box-shadow: 0 .5rem 1rem #00000026;
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: #ffffffd9;
  --bs-toast-header-border-color: #0000000d;
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
  background-clip: padding-box;
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
  position: absolute;
}

.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.toast-header .btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: .5rem;
  --bs-modal-box-shadow: 0 .125rem .25rem #00000013;
  --bs-modal-inner-border-radius: calc(.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  z-index: var(--bs-modal-zindex);
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.modal-content {
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: .5;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

.modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

.modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 .5rem 1rem #00000026;
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header, .modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header, .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header, .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header, .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header, .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header, .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: .5rem;
  --bs-tooltip-padding-y: .25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: .875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: .375rem;
  --bs-tooltip-opacity: .9;
  --bs-tooltip-arrow-width: .8rem;
  --bs-tooltip-arrow-height: .4rem;
  z-index: var(--bs-tooltip-zindex);
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
  display: block;
}

.tooltip .tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before {
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
  top: -1px;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  left: 0;
}

.bs-tooltip-end .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
  right: -1px;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before {
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
  bottom: -1px;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  right: 0;
}

.bs-tooltip-start .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
  left: -1px;
}

.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: .5rem;
  --bs-popover-inner-border-radius: calc(.5rem - 1px);
  --bs-popover-box-shadow: 0 .5rem 1rem #00000026;
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: .5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.popover .popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

.popover .popover-arrow:before, .popover .popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before, .bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

.bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before, .bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

.bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before, .bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

.bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before, .bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

.bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start), .active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end), .active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev, .carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 2rem;
  height: 2rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.carousel-dark .carousel-control-prev-icon, .carousel-dark .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

.spinner-grow, .spinner-border {
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-border-width: .25em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 .125rem .25rem #00000013;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.offcanvas {
  z-index: var(--bs-offcanvas-zindex);
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .5;
}

.offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.placeholder {
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  display: inline-block;
}

.placeholder.btn:before {
  content: "";
  display: inline-block;
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: 2s ease-in-out infinite placeholder-glow;
}

@keyframes placeholder-glow {
  50% {
    opacity: .2;
  }
}

.placeholder-wave {
  animation: 2s linear infinite placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.text-bg-primary {
  color: #000 !important;
  background-color: RGBA(4, 153, 137, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(255, 255, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(22, 26, 32, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #049989 !important;
}

.link-primary:hover, .link-primary:focus {
  color: #36ada1 !important;
}

.link-secondary {
  color: #6c757d !important;
}

.link-secondary:hover, .link-secondary:focus {
  color: #565e64 !important;
}

.link-success {
  color: #198754 !important;
}

.link-success:hover, .link-success:focus {
  color: #146c43 !important;
}

.link-info {
  color: #0dcaf0 !important;
}

.link-info:hover, .link-info:focus {
  color: #3dd5f3 !important;
}

.link-warning {
  color: #ffc107 !important;
}

.link-warning:hover, .link-warning:focus {
  color: #ffcd39 !important;
}

.link-danger {
  color: #dc3545 !important;
}

.link-danger:hover, .link-danger:focus {
  color: #b02a37 !important;
}

.link-light, .link-light:hover, .link-light:focus {
  color: #fff !important;
}

.link-dark {
  color: #161a20 !important;
}

.link-dark:hover, .link-dark:focus {
  color: #12151a !important;
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vr {
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: .1;
}

.border-opacity-25 {
  --bs-border-opacity: .25;
}

.border-opacity-50 {
  --bs-border-opacity: .5;
}

.border-opacity-75 {
  --bs-border-opacity: .75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-2 {
  margin-right: .5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: .25rem !important;
}

.ms-2 {
  margin-left: .5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: .25rem !important;
}

.pe-2 {
  padding-right: .5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: .25rem !important;
}

.ps-2 {
  padding-left: .5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: .25;
}

.text-opacity-50 {
  --bs-text-opacity: .5;
}

.text-opacity-75 {
  --bs-text-opacity: .75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: .25rem !important;
  }

  .mt-sm-2 {
    margin-top: .5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: .25rem !important;
  }

  .me-sm-2 {
    margin-right: .5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: .25rem !important;
  }

  .ms-sm-2 {
    margin-left: .5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: .25rem !important;
  }

  .pt-sm-2 {
    padding-top: .5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: .25rem !important;
  }

  .pe-sm-2 {
    padding-right: .5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: .25rem !important;
  }

  .ps-sm-2 {
    padding-left: .5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: .25rem !important;
  }

  .gap-sm-2 {
    gap: .5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: .25rem !important;
  }

  .mt-md-2 {
    margin-top: .5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: .25rem !important;
  }

  .me-md-2 {
    margin-right: .5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: .25rem !important;
  }

  .ms-md-2 {
    margin-left: .5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: .25rem !important;
  }

  .pt-md-2 {
    padding-top: .5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: .25rem !important;
  }

  .pe-md-2 {
    padding-right: .5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: .25rem !important;
  }

  .ps-md-2 {
    padding-left: .5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: .25rem !important;
  }

  .gap-md-2 {
    gap: .5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: .25rem !important;
  }

  .mt-lg-2 {
    margin-top: .5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: .25rem !important;
  }

  .me-lg-2 {
    margin-right: .5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: .25rem !important;
  }

  .ms-lg-2 {
    margin-left: .5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: .25rem !important;
  }

  .pt-lg-2 {
    padding-top: .5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: .25rem !important;
  }

  .pe-lg-2 {
    padding-right: .5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: .25rem !important;
  }

  .ps-lg-2 {
    padding-left: .5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: .25rem !important;
  }

  .gap-lg-2 {
    gap: .5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: .25rem !important;
  }

  .mt-xl-2 {
    margin-top: .5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: .25rem !important;
  }

  .me-xl-2 {
    margin-right: .5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: .25rem !important;
  }

  .ms-xl-2 {
    margin-left: .5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: .25rem !important;
  }

  .pt-xl-2 {
    padding-top: .5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: .25rem !important;
  }

  .pe-xl-2 {
    padding-right: .5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: .25rem !important;
  }

  .ps-xl-2 {
    padding-left: .5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: .25rem !important;
  }

  .gap-xl-2 {
    gap: .5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: .25rem !important;
  }

  .m-xxl-2 {
    margin: .5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: .25rem !important;
  }

  .me-xxl-2 {
    margin-right: .5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: .25rem !important;
  }

  .p-xxl-2 {
    padding: .5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: .25rem !important;
  }

  .gap-xxl-2 {
    gap: .5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

.scroll-down .header-nav {
  filter: drop-shadow(0 -10px 10px #aaa);
  transform: translateY(80px);
  background: #fff !important;
}

.scroll-down .header-nav .navbar-nav .nav-item .nav-link {
  color: #161a20 !important;
}

.scroll-up .header-nav {
  filter: drop-shadow(0 -10px 10px #aaa);
  transform: translateY(80px);
  background: #fff !important;
}

.scroll-up .header-nav .navbar-nav .nav-item .nav-link {
  color: #161a20 !important;
}

.contact-page .header-nav .navbar-nav .nav-item .nav-link, .homepage .header-nav .navbar-nav .nav-item .nav-link {
  color: #fff;
}

.prod-page .header-nav, .applications-page .header-nav {
  background: #fff !important;
}

.header-nav {
  height: 80px;
  max-width: 100vw;
  z-index: 9999;
  width: 100vw;
  box-shadow: none;
  filter: none;
  transition: all .3s ease-in-out;
  position: fixed;
  top: -80px;
  left: 0;
  transform: translateY(80px);
  background: none !important;
}

.header-nav .navbar-brand {
  max-height: 50px;
  max-width: 180px;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.header-nav .navbar-brand .header-logo {
  height: 48px;
  width: auto;
}

.header-nav .custom-toggler.navbar-toggler {
  border-color: #108477;
  border-radius: 4px;
  padding: 0 4px;
}

.header-nav .custom-toggler.navbar-toggler .navbar-toggler-icon {
  height: 24px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='20' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.header-nav .dropdown .dropdown-toggle:after {
  width: 100%;
  border-width: 1px;
  display: none;
}

.header-nav .dropdown .dropdown-toggle:hover, .header-nav .dropdown .dropdown-toggle:focus, .header-nav .dropdown .dropdown-toggle:active, .header-nav .dropdown .dropdown-toggle.active, .header-nav .dropdown .dropdown-toggle.show {
  display: block;
}

.header-nav .dropdown .dropdown-menu {
  min-height: unset;
  background: #fff;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 12px 0;
  top: calc(100% + 22px);
  box-shadow: 0 8px 24px #00000014;
}

.header-nav .dropdown .dropdown-menu.submenu.level2 {
  min-height: 264px;
}

.header-nav .dropdown .dropdown-menu .dropdown-item {
  letter-spacing: .2em;
  text-transform: uppercase;
  color: #161a20;
  height: 56px;
  background: none;
  border-bottom: 1px solid #d0d1d2;
  margin-bottom: 0;
  padding: 18px 40px 18px 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  position: relative;
}

.header-nav .dropdown .dropdown-menu .dropdown-item span {
  width: 24px;
  height: 24px;
  z-index: 1;
  background: none;
  display: block;
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  transform: translateY(-50%);
}

.header-nav .dropdown .dropdown-menu .dropdown-item:hover, .header-nav .dropdown .dropdown-menu .dropdown-item:focus, .header-nav .dropdown .dropdown-menu .dropdown-item:active, .header-nav .dropdown .dropdown-menu .dropdown-item.active {
  color: #108477;
  background: none;
}

.header-nav .dropdown .dropdown-menu .dropdown-item:hover span, .header-nav .dropdown .dropdown-menu .dropdown-item:focus span, .header-nav .dropdown .dropdown-menu .dropdown-item:active span, .header-nav .dropdown .dropdown-menu .dropdown-item.active span {
  background: url("prod-title-icon-active.f7f6327b.svg") center no-repeat;
}

.header-nav .dropdown .dropdown-menu > li {
  background-color: #0000;
  padding: 12px 48px;
}

.header-nav .dropdown .dropdown-menu > li:hover {
  background-color: inherit;
}

.header-nav .dropdown .dropdown-menu > li:hover > .submenu {
  display: block;
}

.header-nav .dropdown .dropdown-menu > li .submenu {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
}

.header-nav .dropdown .dropdown-menu > li .submenu-left {
  left: auto;
  right: 100%;
}

.header-nav .navbar-nav .nav-item {
  position: relative;
}

.header-nav .navbar-nav .nav-item .nav-link {
  letter-spacing: .4em;
  text-transform: uppercase;
  color: #161a20;
  z-index: 9;
  margin-left: 36px;
  margin-right: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  position: relative;
}

.header-nav .navbar-nav .nav-item .nav-link:after {
  content: "";
  height: 1px;
  width: 100%;
  z-index: 1;
  background-color: #108477;
  display: none;
  position: absolute;
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.header-nav .navbar-nav .nav-item .nav-link:hover, .header-nav .navbar-nav .nav-item .nav-link:focus, .header-nav .navbar-nav .nav-item .nav-link:active, .header-nav .navbar-nav .nav-item .nav-link.active {
  color: #108477;
}

.header-nav .navbar-nav .nav-item .nav-link:hover:after, .header-nav .navbar-nav .nav-item .nav-link:focus:after, .header-nav .navbar-nav .nav-item .nav-link:active:after, .header-nav .navbar-nav .nav-item .nav-link.active:after {
  display: block;
}

.header-nav .navbar-nav .nav-item:first-child .nav-link {
  margin-left: 0;
}

.header-nav #getInstantQuote {
  letter-spacing: 0;
  color: #108477;
  font-size: 12px;
  font-weight: 800;
  line-height: 20px;
}

.header-nav #getInstantQuote:after {
  display: block;
}

@media (min-width: 1600px) {
  .header-nav .dropdown .dropdown-menu > li .submenu.level2 {
    min-height: 264px;
  }
}

@media (max-width: 1600px) and (min-width: 1200px) {
  .header-nav .dropdown .dropdown-menu > li {
    padding: 0 20px;
  }
}

@media (max-width: 1199.98px) {
  .header-nav .navbar-brand .header-logo {
    height: 42px;
  }

  .header-nav .navbar-nav .nav-item .nav-link {
    letter-spacing: 1px;
    margin-left: 20px;
  }

  .header-nav .dropdown .dropdown-menu > li {
    padding: 0 15px;
  }

  .header-nav .dropdown .dropdown-menu > li:last-child .dropdown-item {
    border-bottom: 0;
  }

  .header-nav .dropdown .dropdown-menu .dropdown-item {
    letter-spacing: .15em;
    height: 40px;
    padding: 10px 30px 10px 0;
    font-size: 10px;
  }

  .header-nav .dropdown .dropdown-menu .dropdown-item span {
    background-size: 75% !important;
  }
}

@media (max-width: 991px) {
  .dropdown-menu .dropdown-menu {
    margin-bottom: .5rem;
    margin-left: .7rem;
    margin-right: .7rem;
  }

  .contact-page .header-nav .navbar-nav .nav-item .nav-link, .homepage .header-nav .navbar-nav .nav-item .nav-link {
    color: #161a20;
  }

  .header-nav {
    height: auto;
    padding: 10px 40px;
    background: #fff !important;
  }

  .header-nav #main_nav {
    margin: 10px 0;
  }

  .header-nav .navbar-nav .nav-item .nav-link {
    margin-left: 0;
    padding: 10px 15px;
  }

  .header-nav .navbar-nav .nav-item .nav-link:after {
    display: none !important;
  }

  .header-nav .navbar-nav .nav-item .nav-link.show {
    background-color: #f2f9fc;
  }

  .header-nav .dropdown .dropdown-menu {
    max-width: calc(100% - 4px);
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 10px 20px 20px;
    position: relative;
    left: 50%;
    overflow: hidden;
    transform: translateX(-50%);
  }

  .header-nav .dropdown .dropdown-menu .level1 {
    border-bottom: 1px solid #f2f9fc;
    font-size: 9px;
  }

  .header-nav .dropdown .dropdown-menu .level1 span {
    background: url("prod-title-icon-active.f7f6327b.svg") center no-repeat;
    right: 15px;
  }

  .header-nav .dropdown .dropdown-menu.submenu.level2 {
    box-shadow: none;
    max-width: 100%;
    border-radius: 4px;
    margin-bottom: 0;
    padding: 0;
    font-size: 9px;
    position: static;
    transform: none;
    min-height: unset !important;
    display: block !important;
  }

  .header-nav .dropdown .dropdown-menu.submenu.level2 li {
    padding: 0;
  }

  .header-nav .dropdown .dropdown-menu.submenu.level2 li .dropdown-item {
    background-color: #f2f9fc;
    border-bottom: 1px solid #e1f0f7;
    padding: 5px 15px;
    font-size: 9px;
  }

  .header-nav .dropdown .dropdown-menu.submenu.level2 li .dropdown-item span {
    background: url("prod-title-icon-active.f7f6327b.svg") center no-repeat;
    right: 15px;
  }

  .header-nav .dropdown .dropdown-menu.submenu.level2 li:last-child .dropdown-item {
    border-bottom: 0;
  }

  .header-nav .dropdown .dropdown-menu .dropdown-item {
    height: auto;
    font-size: 11px;
    line-height: 20px;
  }

  .header-nav .dropdown .dropdown-menu > li {
    padding: 0;
  }

  .header-nav .dropdown .dropdown-menu > li .dropdown-item {
    padding: 8px 0;
    font-size: 11px;
    font-weight: 700;
  }
}

@media (max-width: 575.98px) {
  .header-nav {
    height: auto;
    padding-left: 0;
    padding-right: 0;
  }

  .header-nav .navbar-brand {
    margin-left: 15px;
  }

  .header-nav .navbar-brand .header-logo {
    height: 36px;
  }

  .header-nav .custom-toggler {
    margin-right: 15px;
  }

  .header-nav .dropdown .dropdown-menu {
    max-width: calc(100% - 30px);
    margin-bottom: 5px;
    padding: 5px 15px 15px;
  }

  .header-nav .dropdown .dropdown-menu .dropdown-item {
    font-size: 9px;
    line-height: 18px;
  }

  .header-nav #getInstantQuote {
    letter-spacing: .1em;
    font-size: 10px;
    font-weight: 500;
  }

  .header-nav #getInstantQuote:after {
    display: none;
  }
}

.product-card {
  width: 323px;
  height: 500px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #b4b4b4;
  padding: 35px 25px;
  display: block;
  text-decoration: none !important;
}

.product-card .catg {
  letter-spacing: .24em;
  text-transform: uppercase;
  color: #161a20;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  position: relative;
}

.product-card .catg span {
  position: absolute;
  left: auto;
  right: 0;
}

.product-card .catg span img {
  object-fit: cover;
}

.product-card .ttl {
  letter-spacing: .04em;
  color: #161a20;
  margin-top: 12px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.product-card .prod-img {
  width: 187px;
  height: 250px;
  margin: 50px auto 20px;
  position: relative;
}

.product-card .prod-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.product-card .prod-info {
  color: #161a20;
  margin-top: 56px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  display: block;
  position: relative;
}

.product-card .prod-info .prod-info-icon {
  position: absolute;
  top: -2px;
  left: auto;
  right: 0;
}

.product-card .prod-info .prod-info-icon.prod-info-icon-normal {
  opacity: 1;
}

.product-card .prod-info .prod-info-icon.prod-info-icon-active {
  opacity: 0;
}

.product-card:hover, .product-card:focus, .product-card:active {
  background: #f2f9fc;
}

.product-card:hover .prod-info-icon.prod-info-icon-normal, .product-card:focus .prod-info-icon.prod-info-icon-normal, .product-card:active .prod-info-icon.prod-info-icon-normal {
  opacity: 0;
}

.product-card:hover .prod-info-icon.prod-info-icon-active, .product-card:focus .prod-info-icon.prod-info-icon-active, .product-card:active .prod-info-icon.prod-info-icon-active {
  opacity: 1;
}

.applications-card {
  height: 390px;
  z-index: 1;
  background: #fff;
  border: 1px solid #d5d6d7;
  flex-direction: column;
  justify-content: flex-end;
  padding: 40px 32px;
  display: flex;
  position: relative;
}

.applications-card .applications-card-img {
  height: 209px;
  width: 209px;
  z-index: -1;
  display: block;
  position: absolute;
  top: 0;
  left: auto;
  right: 16px;
}

.applications-card .applications-card-img img {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
}

.applications-card .applications-card-box {
  max-width: 228px;
}

.applications-card .applications-card-box .applications-card-ttl {
  letter-spacing: .02em;
  text-transform: capitalize;
  color: #18222f;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

.applications-card .applications-card-box .p {
  color: #767b80;
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.applications-card .lnk {
  height: 0;
  width: 100%;
  color: #049989;
  opacity: 0;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  text-decoration: none;
  display: block;
  position: relative;
}

.applications-card .lnk:after {
  content: "";
  height: 32px;
  width: 32px;
  z-index: 1;
  background: url("prod-title-icon-active.f7f6327b.svg") center no-repeat;
  display: block;
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
}

.applications-card:hover {
  background: #f2f9fc;
  border-color: #32363c;
}

.applications-card:hover .lnk {
  height: 32px;
  opacity: 1;
}

.brand-card {
  min-height: 324px;
  max-width: 492px;
  cursor: default;
  background-color: #fff;
  border: 1px solid #f2f9fc;
  flex-direction: column;
  place-content: center space-between;
  align-items: flex-start;
  padding: 36px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.brand-card .brand-card-bg {
  height: 136px;
  width: 136px;
  z-index: 1;
  position: absolute;
  inset: auto 20px 27px auto;
}

.brand-card .brand-card-bg img {
  height: 100%;
  width: 100%;
  position: absolute;
}

.brand-card .ttl {
  letter-spacing: .06em;
  text-transform: uppercase;
  color: #161a20;
  width: 100%;
  color: #049989;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  text-decoration: none;
  position: relative;
}

.brand-card .ttl:before {
  height: 48px;
  width: 48px;
  background: url("prod-title-icon-active.f7f6327b.svg") center no-repeat;
  display: block;
  position: absolute;
  top: 0;
  left: auto;
  right: -6px;
}

.brand-card .p {
  color: #161a20;
  max-width: 230px;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

.brand-card:hover, .brand-card:focus, .brand-card:active {
  background-color: #f2f9fc;
  text-decoration: none;
}

.brand-card:hover .ttl, .brand-card:focus .ttl, .brand-card:active .ttl {
  color: #049989;
}

.brand-card:hover .ttl:before, .brand-card:focus .ttl:before, .brand-card:active .ttl:before {
  height: 48px;
  width: 48px;
  background: url("prod-title-icon-active.f7f6327b.svg") center no-repeat;
  display: block;
  position: absolute;
  top: 0;
  left: auto;
  right: -6px;
}

.formula-card {
  background: #fff;
  border: 1px solid #d0d1d2;
  padding: 40px 24px;
}

.formula-card .formula-card-icon {
  width: 42px;
  height: 42px;
  margin-bottom: 32px;
  display: block;
  position: relative;
}

.formula-card .formula-card-icon img {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.formula-card .ttl {
  color: #161a20;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
}

.formula-card .p {
  color: #161a20;
  margin-top: 12px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.formula-card:hover, .formula-card:focus, .formula-card:active {
  background-color: #f2f9fc;
  border-color: #108477;
}

.formula-card:hover .ttl, .formula-card:focus .ttl, .formula-card:active .ttl {
  color: #108477;
}

@media (max-width: 1199.98px) {
  .brand-card {
    min-height: 250px;
    width: calc(50% - 10px);
    padding: 20px 15px;
    min-width: calc(50% - 10px) !important;
    max-width: calc(50% - 10px) !important;
  }

  .brand-card .brand-card-bg {
    height: 110px;
    width: 110px;
    bottom: 20px;
    right: 15px;
  }

  .brand-card .ttl {
    font-size: 18px;
    line-height: 22px;
  }

  .brand-card .p {
    max-width: 60%;
    font-size: 18px;
    line-height: 24px;
  }

  .applications-card {
    height: 420px;
    padding-bottom: 15px;
  }

  .applications-card .applications-card-img {
    height: 140px;
    width: 150px;
    top: 10px;
  }
}

@media (max-width: 991px) {
  .formula-card .formula-card-icon {
    width: 38px;
    height: 38px;
    margin-bottom: 20px;
  }

  .formula-card .ttl {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
  }

  .formula-card .p {
    font-size: 14px;
  }
}

@media (max-width: 767px) and (min-width: 576px) {
  .brand-card .brand-card-bg {
    height: 70px;
    width: 70px;
    bottom: 20px;
    right: 10px;
  }

  .brand-card .ttl {
    font-size: 16px;
    line-height: 22px;
  }

  .brand-card .p {
    max-width: 72%;
    font-size: 14px;
    font-weight: 400;
  }
}

@media (max-width: 575.98px) {
  .brand-card {
    min-height: 180px;
    width: 100%;
    max-width: 100% !important;
    min-width: 100% !important;
  }

  .brand-card .ttl {
    font-size: 16px;
    line-height: 20px;
  }

  .brand-card .ttl:before {
    height: 36px !important;
    width: 48px !important;
    background-size: 48% !important;
  }

  .brand-card .p {
    font-size: 14px;
    line-height: 18px;
  }

  .brand-card .brand-card-bg {
    height: 92px;
    width: 92px;
    bottom: 5px;
    right: 5px;
  }

  .product-card {
    width: 100%;
    height: 360px;
    padding: 20px 15px;
  }

  .product-card .catg {
    margin-bottom: 0;
    font-size: 10px;
    line-height: 12px;
  }

  .product-card .ttl {
    margin-top: 8px;
    font-size: 14px;
    line-height: 18px;
  }

  .product-card .prod-img {
    width: 142px;
    height: 190px;
    margin: 15px auto;
  }

  .product-card .prod-info {
    margin-top: 48px;
    font-size: 11px;
    line-height: 17px;
  }

  .product-card .prod-info .prod-info-icon {
    position: absolute;
    top: -2px;
    left: auto;
    right: 0;
  }

  .product-card .prod-info .prod-info-icon img {
    height: 20px;
    width: 21px;
  }

  .product-card .prod-info .prod-info-icon.prod-info-icon-normal {
    opacity: 1;
  }

  .product-card .prod-info .prod-info-icon.prod-info-icon-active {
    opacity: 0;
  }

  .formula-card {
    padding: 20px 15px;
  }

  .formula-card .formula-card-icon {
    width: 32px;
    height: 32px;
    margin-bottom: 20px;
  }

  .formula-card .ttl {
    font-size: 18px;
    line-height: 22px;
  }

  .formula-card .p {
    margin-top: 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .applications-card {
    height: 240px;
    padding: 10px 15px;
  }

  .applications-card .applications-card-img {
    height: 124px;
    width: 124px;
  }

  .applications-card .applications-card-box .applications-card-ttl {
    font-size: 18px;
    line-height: 22px;
  }

  .applications-card .applications-card-box .p {
    font-size: 14px;
    line-height: 18px;
  }

  .applications-card .lnk {
    height: 20px;
    opacity: 1;
    margin-top: 8px;
    line-height: 20px;
  }

  .applications-card:hover .lnk {
    height: 20px;
  }
}

@keyframes slidertop {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-180%);
  }
}

* {
  font-family: Outfit, sans-serif;
}

body {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.st {
  transition: all .2s ease-in-out;
}

img {
  object-fit: cover;
}

.bttn {
  border-radius: 0;
}

.bttn-hero {
  width: 323px;
  height: 64px;
  color: #fff;
  border: 0;
  margin-top: 56px;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  box-shadow: none !important;
}

.bttn-hero:focus, .bttn-hero:hover, .bttn-hero:active {
  color: #fff;
  border: 0;
  background-color: #049989 !important;
}

.owl-carousel.product-cards-carousel .owl-stage-outer, .owl-carousel.other-product-cards-carousel .owl-stage-outer {
  max-height: 520px;
}

.owl-carousel.other-product-cards-carousel .owl-nav svg circle, .owl-carousel.other-product-cards-carousel .owl-nav svg g path {
  stroke: #108477;
}

.owl-carousel.other-product-cards-carousel .owl-dots .owl-dot, .owl-carousel.other-product-cards-carousel .owl-dots .owl-dot.active {
  background: #108477;
}

.owl-carousel .owl-nav {
  position: absolute;
  inset: auto 90px -15px auto;
}

.owl-carousel .owl-nav .owl-prev {
  margin-right: 15px;
}

.owl-carousel .owl-nav .owl-prev svg, .owl-carousel .owl-nav .owl-next svg {
  height: 40px;
  width: 40px;
}

.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next {
  opacity: 1;
  transition: all .2s ease-in-out;
}

.owl-carousel .owl-nav .owl-prev.disabled, .owl-carousel .owl-nav .owl-prev:hover, .owl-carousel .owl-nav .owl-next.disabled, .owl-carousel .owl-nav .owl-next:hover {
  opacity: .5;
}

.owl-carousel .owl-dots {
  max-width: calc(100% - 15vw);
  height: 6px;
  justify-content: space-between;
  margin-top: 36px;
  display: flex;
  position: relative;
}

.owl-carousel .owl-dots .owl-dot {
  height: 1px;
  width: 100%;
  background: #767b80;
  transition: all .4s ease-in-out;
  display: block;
}

.owl-carousel .owl-dots .owl-dot.active {
  height: 5px;
  background: #fff;
  position: relative;
  top: -2px;
}

.common-section {
  z-index: 1;
  margin: 0;
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
  overflow: hidden;
}

.common-section.pattern:before {
  content: "";
  width: 540px;
  height: 526px;
  z-index: -2;
  background: url("pattern-1.61b9fc77.png") 100% 0 / 100% no-repeat;
  display: block;
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
}

.custom-container {
  max-width: 1112px;
}

.sec-ttl {
  color: inherit;
  font-size: 64px;
  font-weight: 800;
  line-height: 96px;
}

.sec-ttl2 {
  color: inherit;
  font-size: 48px;
  font-weight: 800;
  line-height: 56px;
}

.sec-subttl {
  color: #f1f1f1;
  max-width: 1000px;
  margin-top: 40px;
  font-size: 40px;
  font-weight: 300;
  line-height: 56px;
}

.custom-table.table {
  color: #161a20;
  border: 1px solid #9b9b9b;
}

.custom-table.table thead th {
  text-transform: capitalize;
  color: #1a1c80;
  min-width: 240px;
  background-color: #f1f1f1;
  border-top: 0;
  border-bottom: 1px solid #9b9b9b;
  padding: 40px 40px 24px;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.custom-table.table tbody tr td {
  text-transform: capitalize;
  border-color: #ddd;
  padding: 20px 40px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.custom-table.table tbody tr td .err {
  display: inline-table;
}

.custom-table.table tbody tr td .err em {
  height: 8px;
  text-align: center;
  padding: 0 8px;
  font-style: normal;
  display: block;
  position: relative;
  top: -3px;
}

.custom-table.table tbody tr:last-of-type td {
  border: 0;
}

.custom-table.table-striped > tbody > tr:nth-of-type(2n+1) {
  --bs-table-accent-bg: $ light3;
  color: #161a20;
  background-color: #f1f1f1;
}

.custom-table.table-striped > tbody > tr:nth-of-type(2n+1) > * {
  --bs-table-accent-bg: $ light3;
  color: #161a20;
}

.custom-table.table-striped > tbody > tr:nth-of-type(2n) {
  --bs-table-accent-bg: $ white;
  color: #161a20;
  background-color: #fff;
}

.custom-table.table-striped > tbody > tr:nth-of-type(2n) > * {
  --bs-table-accent-bg: $ white;
  color: #161a20;
}

.hero-section {
  height: calc(100vh - 80px);
  min-height: 800px;
  background-image: url("home-banner.30199b05.png");
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: 100vw calc(100vh - 80px);
  align-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

.hero-section .sec-ttl {
  color: #161a20;
  font-size: 64px;
  font-weight: 800;
  line-height: 80px;
}

.hero-section .sec-ttl span {
  color: #049989;
}

.hero-section .sec-para {
  color: #161a20;
  max-width: 620px;
  margin-top: 32px;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
}

.ordersDelivered-section {
  background: url("orders-delivered-bg.ec6cf5f8.svg") center no-repeat;
}

.ordersDelivered-section .sec-ttl {
  color: #108477;
  border-bottom: 1px solid #e0e4e6;
  margin-bottom: 2px;
  padding-bottom: 12px;
  font-size: 24px;
  font-weight: 500;
  line-height: 100%;
}

.ordersDelivered-section .value {
  letter-spacing: .02em;
  color: #108477;
  margin-bottom: 158px;
  font-size: 220px;
  font-weight: 500;
  line-height: 264px;
  display: block;
}

.ordersDelivered-section .value sup {
  font: inherit;
  padding-left: 1%;
  font-size: 25%;
  font-weight: 400;
  position: absolute;
  top: -50px;
}

.ordersDelivered-section .values-blk-wrapper {
  flex-wrap: wrap;
  place-content: center space-between;
  align-items: center;
  display: flex;
}

.ordersDelivered-section .values-blk-wrapper .values-blk {
  max-width: 49%;
  width: 436px;
}

.ordersDelivered-section .values-blk-wrapper .values-blk .value-ttl {
  color: #364855;
  border-bottom: 1px solid #e0e4e6;
  margin-bottom: 10px;
  padding-bottom: 12px;
  font-size: 24px;
  font-weight: 500;
  line-height: 100%;
}

.ordersDelivered-section .values-blk-wrapper .values-blk .value-number {
  color: #364855;
  font-size: 80px;
  font-weight: 400;
  line-height: 100%;
}

footer {
  display: block;
  overflow: hidden;
}

footer .footer-secondary {
  padding-top: 200px;
  padding-bottom: 50px;
  display: block;
}

footer .footer-secondary .container-fluid, footer .footer-secondary .container-sm, footer .footer-secondary .container-md, footer .footer-secondary .container-lg, footer .footer-secondary .container-xl, footer .footer-secondary .container-xxl {
  padding-left: 50px;
  padding-right: 50px;
}

footer .footer-secondary .footer-ttl {
  color: #fff;
  cursor: pointer;
  margin: 0 0 91px;
  padding: 0;
  font-size: 136px;
  font-weight: 200;
  line-height: 118%;
  display: inline-block;
  position: relative;
  text-decoration: none !important;
}

footer .footer-secondary .footer-ttl:hover, footer .footer-secondary .footer-ttl:focus, footer .footer-secondary .footer-ttl:active {
  color: #108477;
}

footer .footer-secondary .footer-ttl:hover span, footer .footer-secondary .footer-ttl:focus span, footer .footer-secondary .footer-ttl:active span {
  background-color: #108477;
  transform: rotateZ(-45deg);
}

footer .footer-secondary .footer-ttl span {
  content: "";
  width: 96px;
  height: 96px;
  transform-origin: 50%;
  background: #0e1014 url("footer-arrow.09b03456.png") center no-repeat;
  border-radius: 100%;
  transition: all .2s ease-in-out;
  display: block;
  position: absolute;
  top: 36px;
  left: auto;
  right: -180px;
  transform: rotateZ(0);
}

footer .footer-secondary .getInTouchCard {
  height: 100%;
  word-break: break-word;
  background: #13161c;
  padding: 40px;
  overflow: hidden;
}

footer .footer-secondary .getInTouchCard:hover, footer .footer-secondary .getInTouchCard:focus, footer .footer-secondary .getInTouchCard:active {
  background: #108477;
}

footer .footer-secondary .getInTouchCard .ttl {
  color: #ddd;
  margin-bottom: 60px;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

footer .footer-secondary .getInTouchCard .p {
  color: #e5e5e5;
  margin-bottom: 0;
  font-size: 26px;
  font-weight: 200;
  line-height: 138%;
}

footer .footer-secondary .getInTouchCard .p b {
  font-weight: 500;
}

footer .footer-secondary .getInTouchCard a {
  color: inherit;
  outline: 0;
  text-decoration: none !important;
}

footer .footer-secondary .getInTouchCard a.cnt:hover, footer .footer-secondary .getInTouchCard a.cnt:focus, footer .footer-secondary .getInTouchCard a.cnt:active {
  text-decoration: underline !important;
}

footer .footer-main {
  background: #13161c;
  padding: 80px 0;
}

footer .footer-main .footer-main-links {
  border-bottom: 1px solid #282a30;
  padding-bottom: 30px;
  display: flex;
}

footer .footer-main .footer-main-links .footer-logo {
  max-width: 60%;
  flex: 1;
}

footer .footer-main .footer-main-links .footer-logo img {
  width: auto;
  height: 42px;
}

footer .footer-main .footer-main-links .footer-links {
  min-width: -moz-fit-content;
  min-width: fit-content;
  flex: 0;
}

footer .footer-main .footer-main-links .footer-links .nav-item {
  align-self: center;
}

footer .footer-main .footer-main-links .footer-links .nav-item .nav-link {
  color: #fff;
  padding-left: 28px;
  padding-right: 28px;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
}

footer .footer-main .footer-main-links .footer-links .nav-item .nav-link.active, footer .footer-main .footer-main-links .footer-links .nav-item .nav-link:hover, footer .footer-main .footer-main-links .footer-links .nav-item .nav-link:focus, footer .footer-main .footer-main-links .footer-links .nav-item .nav-link:active {
  color: #108477;
}

footer .footer-main .footer-main-creds {
  margin-top: 50px;
  display: flex;
}

footer .footer-main .footer-main-creds .credits-wrap {
  flex: 1;
}

footer .footer-main .footer-main-creds .credits {
  color: #9b9b9b;
  flex: 1;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}

footer .footer-main .footer-main-creds .prcplcy-wrap {
  flex: 1;
  justify-content: center;
  gap: 45px;
  display: flex;
}

footer .footer-main .footer-main-creds .prcplcy {
  color: #9b9b9b;
  min-width: 110px;
  min-width: -moz-fit-content;
  min-width: fit-content;
  cursor: pointer;
  outline: 0;
  flex: 0;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-decoration: none;
}

.products-section .container-fluid, .products-section .container-sm, .products-section .container-md, .products-section .container-lg, .products-section .container-xl, .products-section .container-xxl {
  padding-left: 20vw;
}

.products-section .sec-subttl {
  margin-bottom: 40px;
}

.products-section .product-cards-carousel-wrap {
  margin: 0;
}

.products-section .product-cards-carousel-wrap .product-cards-carousel .slick-list .slick-slide {
  margin-right: 16px;
}

.products-section .product-cards-carousel-wrap .product-card {
  width: 100%;
}

.applications-section .sec-ttl {
  margin-bottom: 80px;
}

.applications-section .applications-cards-wrap .applications-cards-flex {
  flex-wrap: wrap;
  display: flex;
}

.applications-section .applications-cards-wrap .applications-cards-flex .applications-card {
  width: calc(33% - 16px);
  margin: 0 8px 16px;
}

.brand-section {
  padding-top: 80px;
}

.brand-section .sec-ttl {
  max-width: 1020px;
  font-size: 56px;
  font-weight: 400;
  line-height: 68px;
}

.brand-section .sec-subttl {
  color: #161a20;
  max-width: 1020px;
  margin-top: 40px;
  font-size: 24px;
  font-weight: 300;
  line-height: 38px;
}

.brand-section .para {
  max-width: 1000px;
  margin-bottom: 40px;
  font-size: 40px;
  font-weight: 300;
  line-height: 56px;
}

.brand-section .brand-section-img {
  width: 100%;
  height: 540px;
  max-width: 1000px;
  margin-top: 56px;
  margin-bottom: 80px;
  position: relative;
}

.brand-section .brand-section-img img {
  height: 100%;
  width: 100%;
  position: absolute;
}

.brand-section .brand-card-wrap {
  flex-wrap: wrap;
  gap: 16px;
  display: flex;
}

.brand-section .brand-card-wrap .brand-card {
  width: 492px;
  margin: 0;
}

.formula-section {
  background-color: #f2f9fc;
}

.formula-section .sec-ttl {
  margin-bottom: 40px;
}

.formula-section .formula-section-img {
  width: 100%;
  height: 632px;
  margin-top: 0;
  margin-bottom: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.formula-section .formula-section-img img {
  width: 100%;
  height: auto;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.formula-section .formula-cards-wrap {
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.formula-section .formula-cards-wrap .formula-card {
  width: calc(50% - 16px);
  margin: 8px;
}

.submit-bttn {
  letter-spacing: 2.25px;
  text-transform: uppercase;
  color: #fff;
  height: 64px;
  width: auto;
  text-align: center;
  border-radius: 0;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 64px;
}

.custom-input {
  height: 48px;
  width: 100%;
  color: #fff;
  box-shadow: none;
  background: none;
  border: 0 #9b9b9b;
  border-bottom: 1px solid #9b9b9b;
  border-radius: 0;
  outline: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.custom-input::placeholder {
  font: inherit;
  color: #d0d1d2;
}

.custom-input:hover, .custom-input:focus, .custom-input:active, .custom-input.active {
  box-shadow: none;
  color: #fff;
  background: none;
  outline: 0;
}

.custom-input:focus {
  border-color: #108477;
}

.customSelect button.dropdown-toggle, .custom-input2 {
  height: 56px;
  width: 100%;
  color: #161a20;
  box-shadow: none;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 0;
  outline: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.customSelect button.dropdown-toggletextarea, .custom-input2textarea {
  height: auto;
}

.customSelect button.dropdown-toggle::placeholder, .custom-input2::placeholder {
  font: inherit;
  color: #9b9b9b;
}

.customSelect button.dropdown-toggle:hover, .customSelect button.dropdown-toggle:focus, .customSelect button.dropdown-toggle:active, .customSelect button.dropdown-toggle.active, .custom-input2:hover, .custom-input2:focus, .custom-input2:active, .custom-input2.active {
  box-shadow: none;
  color: inherit;
  background: #fff;
  outline: 0;
}

.customSelect button.dropdown-toggle:focus, .custom-input2:focus {
  border-color: #108477;
}

.customSelect button.dropdown-toggle.custom-select2, .custom-input2.custom-select2 {
  color: inherit;
  background-image: url("dropdown-select.d402f513.svg");
  background-position: 95%;
  background-repeat: no-repeat;
  background-size: 14px;
}

.customSelect button.dropdown-toggle, .customSelect button.dropdown-toggle:focus, .customSelect button.dropdown-toggle:hover, .customSelect button.dropdown-toggle:active {
  background: #fff !important;
  outline: 0 !important;
}

.customSelect button.dropdown-toggle:after, .customSelect button.dropdown-toggle:focus:after, .customSelect button.dropdown-toggle:hover:after, .customSelect button.dropdown-toggle:active:after {
  width: 14px;
  height: 8px;
  background-image: url("dropdown-select.d402f513.svg");
  background-position: 95%;
  background-repeat: no-repeat;
  background-size: 14px;
  border: 0;
  margin-top: 5px;
  margin-right: 5px;
}

.customSelect .dropdown-toggle .filter-option {
  display: flex;
}

.customSelect .dropdown-toggle .filter-option .filter-option-inner {
  width: 100%;
  align-self: center;
}

.customSelect button.dropdown-toggle[aria-expanded="false"] {
  border: 1px solid #eee;
}

.customSelect button.dropdown-toggle[aria-expanded="true"] {
  border-color: #108477;
  box-shadow: none !important;
}

.quote-section .sec-ttl {
  margin: 0;
  font-size: 56px;
  font-weight: 400;
  line-height: 68px;
}

.quote-section .p {
  color: #d5d6d7;
  max-width: 736px;
  margin-top: 24px;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
}

.quote-section .quote-form-wrap {
  background: #121519;
  margin-top: 56px;
  padding: 32px 34px;
  display: block;
}

.quote-section .quote-form-wrap .quote-form .custom-input {
  width: calc(100% - 60px);
  margin: 0 auto;
}

.quote-section .quote-form-wrap .quote-form .submit-bttn {
  width: 246px;
  background-color: #108477;
}

.quote-section .quote-form-wrap .quote-form .submit-bttn:hover, .quote-section .quote-form-wrap .quote-form .submit-bttn:focus, .quote-section .quote-form-wrap .quote-form .submit-bttn:active {
  color: #fff;
  box-shadow: none;
  background-color: #108477;
  outline: 0;
}

.homepage .bttn-hero {
  line-height: 47px;
}

.specific-section {
  background-color: #f2f9fc;
}

.specific-section .sec-ttl-wrap {
  max-width: 336px;
  margin-right: 40px;
  display: block;
}

.specific-section .sec-ttl-wrap .p {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 156%;
}

.specific-section .sec-ttl {
  font-size: 56px;
  font-weight: 400;
  line-height: 68px;
}

.specific-section .iti {
  width: 100%;
}

.specific-section .specific-form-wrap .specific-form textarea {
  height: auto;
  padding: 16px 20px;
}

.specific-section .specific-form-wrap .specific-form textarea::placeholder {
  line-height: normal;
}

.specific-section .specific-form-wrap .specific-form .submit-bttn {
  width: 191px;
  background-color: #108477;
  margin-top: 10px;
}

.specific-section .specific-form-wrap .specific-form .submit-bttn:hover, .specific-section .specific-form-wrap .specific-form .submit-bttn:focus, .specific-section .specific-form-wrap .specific-form .submit-bttn:active {
  color: #fff;
  box-shadow: none;
  background-color: #108477;
  outline: 0;
}

.productDetails-hero-section {
  min-height: 92vh;
  min-height: 860px;
  background-color: #f1f1f1;
  padding-top: 160px;
  padding-bottom: 136px;
}

.productDetails-hero-section .productDetails-hero-section-bg {
  height: 711px;
  width: 486px;
  z-index: 0;
  display: block;
  position: absolute;
  inset: 136px 0 auto auto;
}

.productDetails-hero-section .productDetails-hero-section-bg img {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.productDetails-hero-section .sec-ttl2 {
  margin-bottom: 40px;
  line-height: 160%;
}

.productDetailsCarousel {
  height: 100%;
  width: 100%;
  position: absolute;
}

.productDetailsCarousel .carousel-indicators {
  margin-bottom: 30px;
}

.productDetailsCarousel .carousel-indicators li {
  width: 64px;
  height: 8px;
  border: 0;
  border-radius: 20px;
  margin-left: 8px;
  margin-right: 8px;
}

.productDetailsCarousel .carousel-inner, .productDetailsCarousel .carousel-inner .carousel-item {
  height: 100%;
  width: 100%;
  position: absolute;
}

.productDetailsCarousel .carousel-inner .carousel-item img {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.applications-page .productDetails-hero-section {
  padding-bottom: 300px;
}

.details-block-wrap .details-block {
  border-top: 1px solid #b4b4b4;
  margin-top: 40px;
  padding: 10px 0;
  display: block;
}

.details-block-wrap .details-block .des, .details-block-wrap .details-block .dtls {
  color: #848484;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.details-block-wrap .details-block .pr-5v {
  padding-right: 5vw;
}

.details-block-wrap .details-block .des {
  text-transform: uppercase;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 24px;
}

.details-block-wrap .details-block .num {
  color: #161a20;
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: 800;
  line-height: 56px;
}

.details-block-wrap .details-block-img {
  height: 390px;
  width: 100%;
  position: relative;
}

.details-block-wrap .details-block-img img {
  height: 100%;
  width: 100%;
  position: absolute;
}

.productDetails-table-section .product-table-wrap {
  margin-top: 40px;
}

.productDetails-table-section .product-table-wrap .custom-table-ttl {
  text-transform: capitalize;
  text-align: center;
  color: #1a1c80;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.productDetails-products-section .sec-ttl2, .applicationDetails-products-section .sec-ttl2 {
  margin-bottom: 40px;
}

.applicationDetails-products-section .product-card {
  width: 100%;
  margin-bottom: 16px;
}

.content {
  display: none;
}

.loadmore-bttn {
  letter-spacing: 2.25px;
  text-transform: uppercase;
  color: #fff;
  height: 64px;
  width: 246px;
  text-align: center;
  background-color: #108477;
  border: 0;
  border-radius: 0;
  margin: 40px auto 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 64px;
  display: block;
}

.loadmore-bttn:hover, .loadmore-bttn:focus, .loadmore-bttn:active {
  color: #fff;
  box-shadow: none;
  background-color: #108477;
  border: 0;
  outline: 0;
}

.loadmore-bttn.noContent {
  color: #fff;
  pointer-events: none;
}

.about-hero-section {
  background-image: linear-gradient(90deg, #fff 0%, #fff0 100%), url("banner.5a87ffa5.png");
  padding-top: 0;
  padding-bottom: 0;
}

.about-hero-section .bttn-hero {
  line-height: 50px;
}

.about-hero-section .sec-ttl {
  letter-spacing: -.02em;
  font-size: 64px;
  font-weight: 700;
  line-height: 120%;
}

.about-hero-section .sec-subttl {
  text-transform: uppercase;
  color: #848484;
  margin-top: 10px;
  font-size: 24px;
  font-weight: 500;
  line-height: 100%;
}

.about-hero-section .scrollDown {
  margin-bottom: 20px;
}

.about-hero-section .sec-para {
  margin-top: 22px;
}

.about-hero-section .bttn-hero {
  margin-top: 100px;
}

.established-section {
  padding-top: 80px;
}

.established-section .para {
  color: #161a20;
  margin-bottom: 0;
  font-size: 32px;
  font-weight: 300;
  line-height: 125%;
}

.established-section .para + .para {
  margin-top: 48px;
}

.established-section .details-block-wrap {
  max-width: 100%;
  margin-left: auto;
  display: block;
}

.established-section .details-block-wrap .details-block {
  margin-top: 96px;
}

.established-section .details-block-wrap .details-block .dtls + .dtls {
  margin-top: 24px;
}

.established-section .details-block-wrap .details-block-img {
  height: 540px;
  margin-top: 80px;
  display: block;
}

.established-section .custom-container .row {
  max-width: 1000px;
}

.certifications-section .sec-subttl {
  margin-top: 20px;
  margin-bottom: 56px;
}

.certifications-section .brand-card-wrap {
  flex-wrap: wrap;
  gap: 16px;
  display: flex;
}

.certifications-section .brand-card-wrap .brand-card {
  min-width: 492px;
  margin: 0;
}

.contact-section {
  padding-top: 168px;
  padding-bottom: 107px;
}

.contact-section .sec-ttl {
  margin: 0;
  font-size: 80px;
  font-weight: 800;
  line-height: 120%;
}

.contact-section .sec-subttl {
  color: #f1f1f1;
  max-width: 555px;
  margin-top: 24px;
  font-size: 32px;
  font-weight: 300;
  line-height: 125%;
}

.contact-section .contact-form-wrap {
  margin-top: 80px;
  display: block;
}

.contact-section .contact-form-wrap .contact-form .iti {
  width: 100%;
}

.contact-section .contact-form-wrap .contact-form .custom-input {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
}

.contact-section .contact-form-wrap .contact-form .custom-input::placeholder {
  font: inherit;
  color: #848484;
  font-weight: 300;
}

.contact-section .contact-form-wrap .contact-form .form-group {
  margin-bottom: 68px;
}

.contact-section .contact-form-wrap .contact-form .submit-bttn {
  color: #fff;
  height: 126px;
  width: 126px;
  background-color: #108477;
  border: 0;
  border-radius: 100%;
  margin: 62px auto 0;
  display: block;
}

.contact-section .contact-form-wrap .contact-form .submit-bttn:hover, .contact-section .contact-form-wrap .contact-form .submit-bttn:focus, .contact-section .contact-form-wrap .contact-form .submit-bttn:active {
  color: #fff;
  box-shadow: none;
  background-color: #108477;
  border: 0;
  outline: 0;
}

.scrollDown .scrollDownButton {
  width: 210px;
  height: 56px;
  text-transform: uppercase;
  color: #848484;
  cursor: pointer;
  border: 1px solid #848484;
  border-radius: 40px;
  padding: 20px 33px 20px 74px;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  display: block;
  position: relative;
  text-decoration: none !important;
}

.scrollDown .scrollDownButton:before, .scrollDown .scrollDownButton:after {
  content: "";
  height: 24px;
  width: 24px;
  z-index: 1;
  background: url("scroll-mouse.c55e398c.svg") center no-repeat;
  display: block;
  position: absolute;
  top: 50%;
  left: 33px;
  transform: translateY(-50%);
}

.scrollDown .scrollDownButton:after {
  background-image: url("scoll-button.eeff7a65.svg");
  animation: 1.2s infinite sdb05;
  top: 46%;
}

@keyframes sdb05 {
  0% {
    opacity: 0;
    transform: translate(0, -15px);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate(0, -10px);
  }
}

.form-wrap .alert {
  max-width: 560px;
  z-index: 11;
  margin: 0;
  position: fixed;
  top: 85px;
  left: 50%;
  transform: translateX(-50%);
}

.other-applications-section {
  padding-top: 0;
  padding-bottom: 0;
}

.other-applications-section .sec-ttl2 {
  border-bottom: 1px solid #848484;
  padding-bottom: 36px;
}

.other-applications-section .other-applications-wrap {
  max-width: 662px;
  width: 100%;
  height: 600px;
  height: 670px;
  margin-right: auto;
  position: relative;
  overflow: hidden;
}

.client-logo-wrap-outer {
  height: calc(100vh - 200px);
  animation: 33s linear infinite alternate-reverse slidertop;
}

.client-logo-wrap-outer:hover {
  animation-play-state: paused;
}

.client-logo-wrap-outer .client-logo-wrap {
  max-width: 100%;
  width: 100%;
  z-index: 3;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 16px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.client-logo-wrap-outer .client-logo-wrap ul {
  max-width: calc(33% - 10px);
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 16px;
  margin: -60px 0 0;
  padding: 0;
  display: flex;
}

.client-logo-wrap-outer .client-logo-wrap ul.ulList2 {
  margin-top: 0;
}

.client-logo-wrap-outer .client-logo-wrap ul li {
  width: 100%;
  text-align: center;
  float: left;
  filter: grayscale();
  -o-transition: all .2s linear 0s;
  margin: 0;
  list-style: none;
  transition: all .2s linear;
}

.client-logo-wrap-outer .client-logo-wrap ul li:hover {
  filter: grayscale(0%);
}

.client-logo-wrap-outer .client-logo-wrap ul li img {
  height: 320px;
  max-width: 210px;
  object-fit: cover;
  width: 100%;
}

.ty-section {
  height: 100vh;
  place-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

.ty-section .thankyou-wrap {
  max-width: 725px;
  display: block;
}

.ty-section .thankyou-wrap .thankyou-msg-wrap {
  max-width: 100%;
  border: 1px solid #e8f6ee;
  place-content: center flex-start;
  align-items: center;
  padding: 30px 25px;
  display: flex;
}

.ty-section .thankyou-wrap .thankyou-msg-wrap .thankyou-msg-img {
  align-self: center;
}

.ty-section .thankyou-wrap .thankyou-msg-wrap .thankyou-msg-img img {
  height: 66px;
  width: 66px;
  margin-right: 20px;
}

.ty-section .thankyou-wrap .thankyou-msg-wrap .thankyou-msg-txt {
  align-self: center;
}

.ty-section .thankyou-wrap .thankyou-msg-wrap .thankyou-msg-txt .ttl {
  text-transform: uppercase;
  color: #18a957;
  margin-bottom: 8px;
  font-size: 28px;
  font-weight: 600;
  line-height: 143%;
}

.ty-section .thankyou-wrap .thankyou-msg-wrap .thankyou-msg-txt .p {
  color: #161a20;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 156%;
}

.ty-section .thankyou-wrap .bttn-hero {
  width: 225px;
  letter-spacing: 2.25px;
  text-transform: uppercase;
  margin: 80px auto 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 56px;
  display: block;
}

@media (min-width: 1600px) {
  footer .footer-secondary .container-fluid, footer .footer-secondary .container-sm, footer .footer-secondary .container-md, footer .footer-secondary .container-lg, footer .footer-secondary .container-xl, footer .footer-secondary .container-xxl {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

@media (max-width: 1600px) and (min-width: 1200px) {
  .sec-ttl, .sec-ttl2 {
    font-size: 42px;
  }

  .sec-subttl {
    font-size: 36px;
    line-height: 130%;
  }

  .details-block-wrap .details-block .num {
    font-size: 34px;
  }

  .specific-section .sec-ttl {
    font-size: 42px;
    line-height: 56px;
  }

  .common-section.pattern:before {
    width: 420px;
    height: 409px;
  }

  .established-section .para {
    font-size: 28px;
  }

  footer .footer-secondary {
    padding-top: 160px;
  }

  footer .footer-secondary .footer-ttl {
    font-size: 98px;
  }

  footer .footer-secondary .footer-ttl span {
    width: 72px;
    height: 72px;
    top: 32px;
    right: -120px;
  }

  footer .footer-secondary .getInTouchCard .p {
    font-size: 22px;
  }

  .hero-section {
    height: 100vh;
    min-height: 100vh;
    background-size: cover;
  }

  .hero-section .custom-container {
    padding-top: 60px;
  }

  .hero-section .sec-ttl {
    max-width: 820px;
    font-size: 48px;
    line-height: 64px;
  }

  .brand-section .sec-ttl {
    font-size: 42px;
    line-height: 52px;
  }

  .brand-section .sec-subttl {
    font-size: 20px;
    line-height: 36px;
  }

  .brand-section .para {
    font-size: 32px;
    line-height: 42px;
  }

  .about-hero-section .sec-para {
    margin-top: 18px;
  }

  .about-hero-section .bttn-hero {
    margin-top: 48px;
  }

  .products-section .container-fluid, .products-section .container-sm, .products-section .container-md, .products-section .container-lg, .products-section .container-xl, .products-section .container-xxl {
    padding-left: 12vw;
  }

  .applications-card {
    padding: 20px 25px;
  }

  .applications-card .applications-card-img {
    height: 150px;
    width: 160px;
  }

  .applications-card .applications-card-box .p {
    font-size: 15px;
    line-height: 22px;
  }

  .applications-card .applications-card-box .applications-card-ttl {
    font-size: 20px;
    line-height: 26px;
  }

  .applications-card .lnk {
    margin-top: 5px;
  }

  .ordersDelivered-section .value {
    font-size: 160px;
    line-height: 100%;
  }

  .productDetailsCarousel .carousel-indicators li {
    width: 52px;
    height: 5px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .productDetails-hero-section {
    min-height: 750px;
    padding-bottom: 100px;
  }

  .productDetails-hero-section .productDetails-hero-section-bg {
    height: 560px;
    width: 380px;
    top: 120px;
  }

  .applications-page .productDetails-hero-section {
    padding-bottom: 100px;
  }
}

@media (max-width: 1199.98px) {
  .common-section {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .custom-container {
    max-width: 960px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .sec-ttl {
    font-size: 38px;
    line-height: 48px;
  }

  .sec-ttl2 {
    font-size: 38px;
  }

  .sec-subttl {
    font-size: 24px;
    line-height: 32px;
  }

  .details-block-wrap .details-block {
    margin-right: 50px;
  }

  .details-block-wrap .details-block .dtls {
    font-size: 14px;
    line-height: 20px;
  }

  .details-block-wrap .details-block .num {
    font-size: 28px;
  }

  .details-block-wrap .details-block .pr-5v {
    text-align: justify;
    padding-right: 0;
  }

  .common-section.pattern:before {
    width: 320px;
    height: 312px;
  }

  .custom-input, .custom-input2 {
    height: 48px;
    font-size: 14px;
    line-height: 24px;
  }

  .submit-bttn {
    height: 48px;
    line-height: 48px;
  }

  .custom-table.table thead th {
    padding: 20px 30px;
    font-size: 18px;
  }

  .custom-table.table tbody tr td {
    padding: 15px 30px;
    font-size: 14px;
  }

  .owl-carousel.product-cards-carousel .owl-stage-outer, .owl-carousel.other-product-cards-carousel .owl-stage-outer {
    max-height: 400px;
  }

  .product-card {
    width: 300px;
    height: 385px;
    padding: 20px 15px;
  }

  .product-card .catg {
    letter-spacing: .14em;
    font-size: 11px;
    font-weight: 500;
  }

  .product-card .ttl {
    font-size: 14px;
    font-weight: 500;
  }

  .product-card .prod-img {
    width: 151px;
    height: 200px;
    margin: 30px auto 20px;
  }

  .product-card .prod-info {
    margin-top: 36px;
  }

  .loadmore-bttn {
    height: 48px;
    margin-top: 15px;
    line-height: 48px;
  }

  footer .footer-secondary {
    padding-top: 100px;
  }

  footer .footer-secondary .footer-ttl {
    margin-bottom: 60px;
    font-size: 72px;
  }

  footer .footer-secondary .footer-ttl span {
    width: 72px;
    height: 72px;
    top: 10px;
    right: -100px;
  }

  footer .footer-secondary .getInTouchCard {
    padding: 30px 20px;
  }

  footer .footer-secondary .getInTouchCard .ttl {
    margin-bottom: 30px;
    font-size: 16px;
  }

  footer .footer-secondary .getInTouchCard .p {
    font-size: 18px;
  }

  footer .footer-main {
    padding: 40px 0;
  }

  footer .footer-main .footer-main-links .footer-links .nav-item .nav-link {
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 500;
  }

  .owl-carousel .owl-nav {
    right: 20px;
  }

  .hero-section {
    height: 100vh;
    min-height: 100vh;
    background-size: cover;
  }

  .hero-section .custom-container {
    padding-top: 60px;
  }

  .hero-section .sec-ttl {
    max-width: 820px;
    font-size: 48px;
    line-height: 64px;
  }

  .about-hero-section .sec-para {
    margin-top: 18px;
  }

  .about-hero-section .bttn-hero {
    margin-top: 48px;
  }

  .contact-section .contact-form-wrap .contact-form .custom-input {
    height: 48px;
    font-size: 14px;
    line-height: 24px;
  }

  .contact-section .contact-form-wrap .contact-form .form-group {
    margin-bottom: 48px;
  }

  .brand-section .sec-ttl {
    font-size: 36px;
    line-height: 42px;
  }

  .brand-section .sec-subttl {
    margin-top: 30px;
    font-size: 18px;
    line-height: 24px;
  }

  .brand-section .brand-section-img {
    height: 480px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .brand-section .brand-section .para {
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 36px;
  }

  .established-section .para {
    font-size: 20px;
  }

  .established-section .details-block-wrap .details-block-img {
    height: 420px;
  }

  .specific-section .sec-ttl {
    font-size: 38px;
    line-height: 48px;
  }

  .specific-section .form-group {
    margin-bottom: 0;
  }

  .specific-section.other-applications-section {
    padding: 0;
  }

  .productDetailsCarousel .carousel-indicators li {
    width: 52px;
    height: 5px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .productDetails-hero-section {
    min-height: 95vh;
    padding-top: 120px;
    padding-bottom: 80px;
  }

  .productDetails-hero-section .productDetails-hero-section-bg {
    height: 48vw;
    width: 33vw;
    top: 120px;
  }

  .products-section .container-fluid, .products-section .container-sm, .products-section .container-md, .products-section .container-lg, .products-section .container-xl, .products-section .container-xxl {
    padding-left: 20px;
    padding-right: 20px;
  }

  .applications-page .productDetails-hero-section {
    padding-bottom: 80px;
  }
}

@media (max-width: 991px) {
  .common-section {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .custom-container {
    max-width: 720px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .sec-ttl2 {
    font-size: 28px;
    line-height: 36px;
  }

  .details-block-wrap .details-block {
    margin-top: 20px;
  }

  .details-block-wrap .details-block .num {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 24px;
  }

  .custom-table.table thead th {
    min-width: 150px;
    padding: 10px 15px;
    font-size: 16px;
  }

  .custom-table.table tbody tr td {
    padding: 7px 15px;
    font-size: 13px;
  }

  .contact-section {
    min-height: 100vh;
    padding-top: 120px;
  }

  .contact-section .sec-ttl {
    font-size: 52px;
    font-weight: 500;
  }

  .contact-section .sec-subttl {
    margin-top: 15px;
    font-size: 24px;
  }

  .about-hero-section.hero-section {
    height: auto;
    min-height: 90vh;
  }

  .formula-section .formula-section-img {
    height: 560px;
    margin-bottom: 30px;
  }

  .formula-section .formula-section-img img {
    width: auto;
    height: 100%;
  }

  .formula-section .formula-cards-wrap {
    gap: 16px;
  }

  .formula-section .formula-cards-wrap .formula-card {
    width: calc(50% - 8px);
    margin: 0;
    padding: 20px 15px;
  }

  .established-section .details-block-wrap .details-block-img {
    height: 360px;
    margin-top: 40px;
  }

  .specific-section .sec-ttl {
    font-size: 28px;
    line-height: 36px;
  }

  .specific-section .sec-ttl-wrap {
    max-width: 100%;
    margin-right: 0;
  }

  .specific-section .sec-ttl-wrap .p {
    margin-bottom: 24px;
    padding-right: 15%;
    font-size: 16px;
    font-weight: 300;
    line-height: 140%;
  }

  .other-applications-section .sec-ttl2 {
    padding-bottom: 24px;
  }

  .productDetails-hero-section {
    min-height: unset;
    padding-top: 120px;
  }

  .productDetails-hero-section .sec-ttl2 {
    margin-bottom: 20px;
  }

  .productDetails-table-section .product-table-wrap {
    margin-top: 20px;
  }
}

@media (max-width: 767px) and (min-width: 576px) {
  .homepage .brand-section .sec-ttl {
    max-width: 100%;
    font-size: 22px;
    line-height: 150%;
  }

  .homepage .brand-section .sec-subttl {
    max-width: 100%;
    margin-top: 20px;
    font-size: 16px;
    line-height: 145%;
  }

  .homepage .brand-section .brand-section-img {
    height: 320px;
    max-width: 100%;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .homepage .brand-section .para {
    max-width: 100%;
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 150%;
  }

  .homepage .applications-card {
    height: auto;
    padding: 20px 15px;
  }

  .homepage .applications-card .applications-card-img {
    height: 110px;
    width: 118px;
  }

  .homepage .applications-card .applications-card-box {
    max-width: unset;
    padding-top: 110px;
  }

  .homepage .applications-card .applications-card-box .applications-card-ttl {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
  }

  .homepage .applications-card .applications-card-box .p {
    font-size: 14px;
    line-height: 20px;
  }

  .homepage .applications-card .lnk {
    height: 32px;
    opacity: 1;
    margin-top: 0;
  }

  .homepage .hero-section .sec-ttl, .about-page .hero-section .sec-ttl {
    max-width: 80%;
    font-size: 36px;
    line-height: 48px;
  }

  .homepage .hero-section .sec-para, .about-page .hero-section .sec-para {
    max-width: 80%;
    margin-top: 15px;
    font-size: 18px;
    line-height: 24px;
  }

  .homepage .hero-section .bttn-hero, .about-page .hero-section .bttn-hero {
    margin-top: 36px;
  }
}

@media (max-width: 767px) {
  .common-section {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .custom-container {
    max-width: 560px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .sec-ttl2 {
    font-size: 28px;
    line-height: 36px;
  }

  .details-block-wrap .details-block {
    margin-right: 0;
  }

  .specific-section .sec-ttl-wrap {
    max-width: 100%;
    margin-bottom: 30px;
    margin-right: 0;
  }

  .specific-section .form-group {
    margin-bottom: 10px;
  }

  .specific-section.other-applications-section {
    padding: 60px 0 0;
  }

  .specific-section.other-applications-section .sec-ttl2 {
    margin-bottom: 30px;
    padding-bottom: 7px;
    display: inline-block;
  }

  .owl-carousel .owl-dots {
    max-width: calc(100% - 20vw);
  }

  .owl-carousel .owl-nav {
    right: 5px;
  }

  .contact-section {
    padding-top: 120px;
  }

  .contact-section .sec-ttl {
    font-size: 48px;
  }

  .contact-section .sec-subttl {
    margin-top: 20px;
    font-size: 20px;
  }

  .ordersDelivered-section {
    background-size: cover;
    padding: 80px 0;
  }

  .ordersDelivered-section .value {
    margin-bottom: 80px;
    font-size: 70px;
    line-height: 100%;
  }

  .ordersDelivered-section .values-blk-wrapper .values-blk .value-ttl {
    font-size: 18px;
    font-weight: 400;
    line-height: 100%;
  }

  .ordersDelivered-section .values-blk-wrapper .values-blk .value-number {
    font-size: 42px;
  }

  .applications-section .applications-cards-wrap .applications-cards-flex {
    gap: 16px;
  }

  .applications-section .applications-cards-wrap .applications-cards-flex .applications-card {
    width: calc(50% - 16px);
    margin: 0;
  }

  .productDetails-hero-section {
    padding-top: 100px;
    padding-bottom: 0;
  }

  .productDetails-hero-section .productDetails-hero-section-bg {
    height: 500px;
    width: 340px;
    margin-bottom: 30px;
    position: relative;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .products-section .container-fluid, .products-section .container-sm, .products-section .container-md, .products-section .container-lg, .products-section .container-xl, .products-section .container-xxl {
    padding-left: 15px;
    padding-right: 15px;
  }

  .applications-page .productDetails-hero-section {
    padding-bottom: 0;
  }

  footer .footer-secondary {
    padding-top: 40px;
    padding-bottom: 0;
  }

  footer .footer-secondary .footer-ttl {
    margin-bottom: 40px;
    font-size: 48px;
  }

  footer .footer-secondary .footer-ttl span {
    width: 52px;
    height: 52px;
    top: 4px;
    right: -80px;
  }

  footer .footer-secondary .container-fluid, footer .footer-secondary .container-sm, footer .footer-secondary .container-md, footer .footer-secondary .container-lg, footer .footer-secondary .container-xl, footer .footer-secondary .container-xxl {
    padding-left: 15px;
    padding-right: 15px;
  }

  footer .footer-secondary .getInTouchCardCol {
    margin-bottom: 15px;
  }

  footer .footer-main .footer-main-links .footer-links .nav {
    gap: 15px;
  }

  footer .footer-main .footer-main-links .footer-links .nav .nav-item .nav-link {
    letter-spacing: 0;
    padding: 7px 0;
    font-size: 12px;
  }

  footer .footer-main .footer-main-creds .credits {
    font-size: 10px;
    font-weight: 400;
  }

  footer .footer-main .footer-main-creds .prcplcy-wrap {
    justify-content: flex-end;
    gap: 15px;
  }
}

@media (max-width: 575.98px) {
  .custom-container {
    max-width: unset;
    max-width: unset;
    padding-left: 15px;
    padding-right: 15px;
  }

  .bttn-hero {
    width: 250px;
    height: 48px;
    margin-top: 30px;
    font-size: 16px;
    line-height: 24px;
  }

  .submit-bttn, .loadmore-bttn {
    width: 100%;
    max-width: 360px;
    height: 48px;
    margin-left: auto;
    margin-right: auto;
    line-height: 48px;
    display: block;
  }

  .common-section {
    padding: 40px 0;
  }

  .sec-ttl, .sec-ttl2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
  }

  .sec-subttl {
    margin-top: 20px;
    font-size: 18px;
    line-height: 28px;
  }

  .custom-input, .custom-input2 {
    height: 44px;
    font-size: 14px;
  }

  .details-block-wrap .details-block {
    margin-top: 20px;
  }

  .details-block-wrap .details-block .des {
    text-transform: uppercase;
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 24px;
  }

  .details-block-wrap .details-block .dtls {
    font-size: 14px;
    line-height: 22px;
  }

  .details-block-wrap .details-block .num {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 30px;
  }

  .details-block-wrap .details-block .num + .dtls {
    font-size: 12px;
    line-height: 12px;
  }

  .custom-table {
    margin-bottom: 5px;
  }

  .custom-table.table thead th {
    min-width: 220px;
    padding: 10px 15px;
    font-size: 16px;
    line-height: 18px;
  }

  .custom-table.table thead th:nth-child(1) {
    min-width: 140px;
  }

  .custom-table.table tbody tr td {
    min-width: unset;
    padding: 10px 15px;
    font-size: 12px;
    line-height: 18px;
  }

  .productDetailsCarousel .carousel-indicators {
    margin-bottom: 10px;
  }

  .productDetailsCarousel .carousel-indicators li {
    width: 40px;
    height: 4px;
  }

  .owl-carousel .owl-dots {
    max-width: calc(100% - 22vw);
  }

  .owl-carousel .owl-dots .owl-dot {
    height: 3px;
  }

  .owl-carousel .owl-dots .owl-dot.active {
    height: 6px;
    top: -2px;
  }

  .owl-carousel .owl-nav {
    bottom: -6px;
    right: 4px;
  }

  .owl-carousel .owl-nav .owl-prev {
    margin-right: 5px;
  }

  .owl-carousel .owl-nav .owl-prev svg, .owl-carousel .owl-nav .owl-next svg {
    height: 24px;
    width: 24px;
  }

  .contact-section {
    padding-top: 120px;
  }

  .contact-section .sec-ttl {
    font-size: 36px;
  }

  .contact-section .sec-subttl {
    margin-top: 10px;
    font-size: 16px;
  }

  .contact-section .contact-form-wrap {
    margin-top: 20px;
  }

  .contact-section .contact-form-wrap .contact-form .custom-input {
    height: 44px;
  }

  .contact-section .contact-form-wrap .contact-form .form-group {
    margin-bottom: 20px;
  }

  .contact-section .contact-form-wrap .contact-form .submit-bttn {
    height: 48px;
    width: 100%;
    max-width: 360px;
    border-radius: 0;
    margin-top: 60px;
    line-height: 48px;
  }

  .about-hero-section .bttn-hero, .homepage .hero-section .bttn-hero {
    line-height: 36px;
  }

  .about-hero-section.hero-section {
    height: auto;
    min-height: unset;
  }

  .established-section .para {
    font-size: 16px;
  }

  .established-section .details-block-wrap .details-block-img {
    height: 220px;
    margin-top: 40px;
  }

  .hero-section {
    height: auto;
    min-height: unset;
    padding: 150px 0;
  }

  .hero-section .sec-ttl {
    font-size: 26px;
    font-weight: 700;
    line-height: 36px;
  }

  .hero-section .sec-para {
    margin-top: 24px;
    font-size: 16px;
    line-height: 24px;
  }

  .brand-section .sec-ttl {
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
  }

  .brand-section .sec-subttl {
    margin-top: 20px;
    font-size: 16px;
    line-height: 26px;
  }

  .brand-section .brand-section-img {
    width: 100%;
    height: 220px;
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 36px;
    position: relative;
  }

  .brand-section .para {
    max-width: 100%;
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 120%;
  }

  .brand-section .brand-card-wrap {
    flex-wrap: wrap;
  }

  .brand-section .brand-card-wrap .brand-card {
    width: 100%;
  }

  .specific-section .sec-ttl-wrap .p {
    padding-right: 0;
    font-size: 14px;
    line-height: 136%;
  }

  .products-section .container-fluid, .products-section .container-sm, .products-section .container-md, .products-section .container-lg, .products-section .container-xl, .products-section .container-xxl {
    padding-left: 15px;
    padding-right: 15px;
  }

  .products-section .sec-subttl {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .formula-section .sec-ttl {
    margin-bottom: 0;
  }

  .formula-section .formula-section-img {
    height: 420px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .formula-section .formula-cards-wrap {
    gap: 5px;
  }

  .formula-section .formula-cards-wrap .formula-card {
    width: calc(50% - 4px);
  }

  .applications-section .sec-ttl {
    margin-bottom: 12px;
  }

  .applications-section .applications-cards-wrap .applications-cards-flex .applications-card {
    width: 100%;
    margin: 0 0 16px;
  }

  .quote-section .sec-ttl {
    font-size: 30px;
    line-height: 40px;
  }

  .quote-section .p {
    margin-top: 15px;
    font-size: 14px;
    line-height: 20px;
  }

  .quote-section .quote-form-wrap {
    margin-top: 30px;
    padding: 10px 20px 20px;
  }

  .quote-section .quote-form-wrap .quote-form .custom-input {
    width: 100%;
    margin: 0 auto 20px;
  }

  .quote-section .quote-form-wrap .quote-form .submit-bttn {
    width: 100%;
    margin: 20px auto 0;
  }

  .ordersDelivered-section {
    background-size: cover;
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .ordersDelivered-section .sec-ttl {
    padding-bottom: 4px;
    font-size: 18px;
  }

  .ordersDelivered-section .value {
    margin-bottom: 48px;
    font-size: 60px;
    line-height: 100%;
  }

  .ordersDelivered-section .value sup {
    padding-left: 3%;
    font-size: 65%;
    top: 2px;
  }

  .ordersDelivered-section .values-blk-wrapper .values-blk .value-ttl {
    margin-bottom: 0;
    padding-bottom: 4px;
    font-size: 14px;
  }

  .ordersDelivered-section .values-blk-wrapper .values-blk .value-number {
    font-size: 28px;
    line-height: 150%;
  }

  .specific-section .sec-ttl-wrap {
    max-width: unset;
    margin-right: 0;
  }

  .specific-section .sec-ttl {
    font-size: 24px;
    line-height: 120%;
  }

  .specific-section .specific-form-wrap .specific-form textarea {
    height: auto;
    padding: 5px 10px;
  }

  .specific-section .specific-form-wrap .specific-form .submit-bttn {
    width: 100%;
    margin-top: 10px;
  }

  .specific-section.other-applications-section {
    padding: 30px 0 0;
  }

  .specific-section.other-applications-section .sec-ttl2 {
    margin-bottom: 0;
  }

  .specific-section.other-applications-section .other-applications-wrap {
    height: 500px;
    margin: 0;
  }

  .specific-section.other-applications-section .other-applications-wrap .client-logo-wrap-outer {
    height: 420px;
    animation: 16s linear infinite alternate-reverse slidertop;
  }

  .specific-section.other-applications-section .other-applications-wrap .client-logo-wrap-outer:hover {
    animation-play-state: paused;
  }

  .specific-section.other-applications-section .other-applications-wrap .client-logo-wrap-outer .client-logo-wrap {
    gap: 0;
  }

  .specific-section.other-applications-section .other-applications-wrap .client-logo-wrap-outer .client-logo-wrap ul {
    max-width: calc(50% - 8px);
    gap: 5px;
    margin: 0;
  }

  .specific-section.other-applications-section .other-applications-wrap .client-logo-wrap-outer .client-logo-wrap ul li {
    height: 200px;
    max-width: 131px;
  }

  .specific-section.other-applications-section .other-applications-wrap .client-logo-wrap-outer .client-logo-wrap ul li img {
    height: 100%;
    width: auto;
  }

  .specific-section.other-applications-section .other-applications-wrap .client-logo-wrap-outer .client-logo-wrap .ulList1 {
    justify-content: flex-end;
    transform: translateY(-60px);
  }

  .specific-section.other-applications-section .other-applications-wrap .client-logo-wrap-outer .client-logo-wrap .ulList3 {
    display: none;
  }

  .productDetails-hero-section {
    padding-top: 70px;
    padding-bottom: 0;
  }

  .productDetails-hero-section .productDetails-hero-section-bg {
    height: 400px;
    width: 280px;
    margin-bottom: 40px;
  }

  .productDetails-hero-section .sec-ttl2 {
    margin-bottom: 20px;
  }

  .productDetails-table-section .product-table-wrap {
    margin-top: 20px;
  }

  .productDetails-products-section .sec-ttl2, .applicationDetails-products-section .sec-ttl2 {
    margin-bottom: 20px;
  }

  .other-applications-section .other-applications-wrap {
    max-width: 100%;
    width: 100%;
    height: 310px;
    margin: 20px 0;
  }

  .product-card {
    height: 350px;
  }

  .product-card .catg {
    padding-right: 15px;
  }

  .product-card .prod-info {
    margin-top: 20px;
  }

  .applicationDetails-products-section .loadmore-bttn {
    margin-top: 0;
  }

  .applicationDetails-products-section .content:nth-of-type(2n+1) {
    padding-left: 4px;
    padding-right: 2px;
  }

  .applicationDetails-products-section .content:nth-of-type(2n) {
    padding-left: 2px;
    padding-right: 4px;
  }

  footer .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  footer .footer-secondary {
    padding-top: 40px;
    padding-bottom: 0;
  }

  footer .footer-secondary .footer-ttl {
    margin-bottom: 40px;
    font-size: 30px;
    line-height: 40px;
  }

  footer .footer-secondary .footer-ttl span {
    width: 36px;
    height: 36px;
    background-size: 42%;
    top: 4px;
    right: -50px;
  }

  footer .footer-secondary .getInTouchCardCol {
    margin-bottom: 16px;
  }

  footer .footer-secondary .getInTouchCard {
    padding: 20px 15px;
  }

  footer .footer-secondary .getInTouchCard .ttl {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 20px;
  }

  footer .footer-secondary .getInTouchCard .p {
    font-size: 18px;
  }

  footer .footer-main {
    padding: 20px 0;
  }

  footer .footer-main .footer-main-links {
    display: block;
  }

  footer .footer-main .footer-main-links .footer-logo {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
  }

  footer .footer-main .footer-main-links .footer-logo img {
    text-align: center;
    height: 31px;
    margin: 0 auto 20px;
    display: block;
  }

  footer .footer-main .footer-main-links .footer-links .nav {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  footer .footer-main .footer-main-links .footer-links .nav .nav-item .nav-link {
    padding: 0;
    font-size: 14px;
  }

  footer .footer-main .footer-main-creds {
    margin-top: 40px;
  }

  footer .footer-main .footer-main-creds p {
    margin: 0;
  }

  footer .footer-main .footer-main-creds .prcplcy-wrap {
    gap: 10px;
  }

  footer .footer-main .footer-main-creds .prcplcy-wrap .prcplcy {
    font-size: 11px;
    font-weight: 400;
  }

  .ty-section .thankyou-wrap .thankyou-msg-wrap {
    flex-direction: column;
    padding: 20px 15px;
  }

  .ty-section .thankyou-wrap .thankyou-msg-wrap .thankyou-msg-img img {
    height: 40px;
    width: 40px;
    margin-bottom: 15px;
    margin-right: 0;
  }

  .ty-section .thankyou-wrap .thankyou-msg-wrap .thankyou-msg-txt .ttl {
    text-align: center;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    line-height: 32px;
  }

  .ty-section .thankyou-wrap .thankyou-msg-wrap .thankyou-msg-txt .p {
    text-align: center;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    line-height: 22px;
  }

  .ty-section .thankyou-wrap .bttn-hero {
    margin-top: 56px;
    line-height: 40px;
  }
}

@media (max-width: 390px) and (min-width: 320px) {
  .product-card {
    height: 90vw;
    min-height: 325px;
  }

  .product-card .prod-img {
    width: 100%;
    height: 52vw;
    margin: 20px auto;
  }

  .product-card .prod-info {
    margin-top: 2vw;
  }
}

.tel-input.is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") !important;
  border-color: #198754 !important;
}

.tel-input.is-valid:focus {
  box-shadow: 0 0 0 .25rem #19875440 !important;
}

.tel-input.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
  border-color: #dc3545 !important;
}

.tel-input.is-invalid:focus {
  box-shadow: 0 0 0 .25rem #dc354540 !important;
}

/*# sourceMappingURL=index.db748705.css.map */
