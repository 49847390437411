@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './colors';

@-webkit-keyframes slidertop {
  from {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
  to {
    transform: translateY(-180%);
    -webkit-transform: translateY(-180%);
    -moz-transform: translateY(-180%);
    -ms-transform: translateY(-180%);
    -o-transform: translateY(-180%);
  }
}
@keyframes slidertop {
  from {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
  to {
    transform: translateY(-180%);
    -webkit-transform: translateY(-180%);
    -moz-transform: translateY(-180%);
    -ms-transform: translateY(-180%);
    -o-transform: translateY(-180%);
  }
}

* {
  font-family: 'Outfit', sans-serif;
}

body {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: normal;
}

.st {
  transition: all .2s ease-in-out;
}

img {
  object-fit: cover;
}

.bttn {
  border-radius: 0;
}

.bttn-hero {
  width: 323px;
  height: 64px;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-top: 56px;
  box-shadow: none !important;
  border: 0;
  &:focus,
  &:hover,
  &:active {
    color: #FFFFFF;
    border: 0;
    background-color: $primary !important;
  }
}

.owl-carousel {
  &.product-cards-carousel,
  &.other-product-cards-carousel {
    .owl-stage-outer {
      max-height: 520px;
    }
  }
  &.other-product-cards-carousel {
    .owl-nav {
      svg {
        circle {
          stroke: $main
        }
        g path {
          stroke: $main
        }
      }
    }
    .owl-dots {
      .owl-dot {
        background: $main;
        &.active {
          background: $main;
        }
      }
    }
  }
  .owl-nav {
    position: absolute;
    top: auto;
    bottom: -15px;
    left: auto;
    right: 90px;
    .owl-prev {
      margin-right: 15px;
      svg {
        height: 40px;
        width: 40px;
      }
    }
    .owl-next {
      svg {
        height: 40px;
        width: 40px;
      }
    }
    .owl-prev, .owl-next {
      transition: all .2s ease-in-out;
      opacity: 1;
      &.disabled, &:hover {
        opacity: 0.5;
      }
    }
  }
  .owl-dots {
    position: relative;
    display: flex;
    justify-content: space-between;
    max-width: calc(100% - 15vw);
    margin-top: 36px;
    height: 6px;
    .owl-dot {
      display: block;
      height: 1px;
      width: 100%;
      background: $gray3;
      transition: all .4s ease-in-out;
      &.active {
        position: relative;
        top: -2px;
        height: 5px;
        background: $white;
        // width: 30vw;
      }
    }
  }
}

.common-section {
  position: relative;
  margin: 0;
  padding-top: 120px;
  padding-bottom: 120px;
  z-index: 1;
  overflow: hidden;
  &.pattern {
    &::before {
      content: '';
      position: absolute;
      display: block;
      background: url('../assets/pattern-1.png') no-repeat;
      background-position: 100% 0px;
      background-size: 100%;
      width: 540px;
      height: 526px;
      top: 0;
      left: auto;
      right: 0;
      z-index: -2;
    }
  }
}
.custom-container {
  max-width: 1112px;
}

.sec-ttl {
  font-weight: 800;
  font-size: 64px;
  line-height: 96px;
  color: inherit;
}

.sec-ttl2 {
  font-weight: 800;
  font-size: 48px;
  line-height: 56px;
  color: inherit;
}

.sec-subttl {
  font-weight: 300;
  font-size: 40px;
  line-height: 56px;
  color: $light3;
  max-width: 1000px;
  margin-top: 40px;
}

.custom-table {
  &.table {
    border: 1px solid $gray5;
    color: $dark1;
    thead {
      th {
        background-color: $light3;
        border-bottom: 1px solid $gray5;
        border-top: 0;
        padding: 40px 40px 24px;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        text-transform: capitalize;
        color: $tableHead;
        min-width: 240px;
      }
    }
    tbody {
      tr {
        td {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-transform: capitalize;
          border-color: $light2;
          padding: 20px 40px;
          .err {
            display: inline-table;
            em {
              position: relative;
              display: block;
              font-style: normal;
              height: 8px;
              text-align: center;
              padding: 0 8px;
              top: -3px;
            }
          }
        }
        &:last-of-type {
          td {
            border: 0;
          }
        }
      }
    }
  }
  &.table-striped {
    > tbody {
      > tr {
        &:nth-of-type(2n+1) {
          background-color: $light3;
          --bs-table-accent-bg: $light3;
          color: $dark1;
          >* {
            --bs-table-accent-bg: $light3;
            color: $dark1;
          }
        }
        &:nth-of-type(2n) {
          background-color: $white;
          --bs-table-accent-bg: $white;
          color: $dark1;
          >* {
            --bs-table-accent-bg: $white;
            color: $dark1;
          }
        }
      }
    }
  }
}

.hero-section {
  height: calc(100vh - 80px);
  min-height: 800px;
  // background-image: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), url('../assets/homepage/home-banner.png');
  background-image: url('../assets/homepage/home-banner.png');
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: 100vw calc(100vh - 80px);
  display: flex;
  align-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  .sec-ttl {
    font-weight: 800;
    font-size: 64px;
    line-height: 80px;
    color: $dark;
    span {
      color: $primary;
    }
  }
  .sec-para {
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    color: $dark;
    max-width: 620px;
    margin-top: 32px;
    margin-bottom: 0;
  }
}

.ordersDelivered-section {
  background: url('../assets/homepage/orders-delivered-bg.svg') no-repeat center;

  .sec-ttl {
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    color: #108477;
    padding-bottom: 12px;
    margin-bottom: 2px;
    border-bottom: 1px solid #E0E4E6;
  }

  .value {
    display: block;
    font-weight: 500;
    font-size: 220px;
    line-height: 264px;
    letter-spacing: 0.02em;
    color: #108477;
    margin-bottom: 158px;
    sup {
      font: inherit;
      font-size: 25%;
      position: absolute;
      top: -50px;
      padding-left: 1%;
      font-weight: 400;
    }
  }

  .values-blk-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;

    .values-blk {
      max-width: 49%;
      width: 436px;

      .value-ttl {
        font-weight: 500;
        font-size: 24px;
        line-height: 100%;
        color: #364855;
        padding-bottom: 12px;
        margin-bottom: 10px;
        border-bottom: 1px solid #E0E4E6;
      }

      .value-number {
        font-weight: 400;
        font-size: 80px;
        line-height: 100%;
        color: #364855;
      }
    }
  }
}

/* Footer */
footer {
  display: block;
  overflow: hidden;
  .footer-secondary {
    display: block;
    padding-top: 200px;
    padding-bottom: 50px;
    .container-fluid {
      padding-left: 50px;
      padding-right: 50px;
    }
    .footer-ttl {
      position: relative;
      display: inline-block;
      font-weight: 200;
      font-size: 136px;
      line-height: 118%;
      color: $light;
      margin: 0;
      padding: 0;
      margin-bottom: 91px;
      text-decoration: none !important;
      cursor: pointer;
      &:hover, &:focus, &:active {
        color: $main;
        span {
          background-color: $main;
          transform: rotateZ(-45deg);
        }
      }     
      span {
        position: absolute;
        content: '';
        display: block;
        background: $dark2 url('../assets/footer/footer-arrow.png') no-repeat center;
        width: 96px;
        height: 96px;
        top: 36px;
        left: auto;
        right: -180px;
        border-radius: 100%;
        transform: rotateZ(0deg);
        transition: all .2s ease-in-out;
        transform-origin: 50% 50%;
      }
    }
    .getInTouchCard {
      height: 100%;
      padding: 40px 40px;
      background: $dark3;
      overflow: hidden;
      word-break: break-word;
      &:hover, &:focus, &:active {
        background: $main;
      }
      .ttl {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: $light2;
        margin-bottom: 60px;
      }
      .p {
        font-weight: 200;
        font-size: 26px;
        line-height: 138%;
        color: $light1;
        margin-bottom: 0;
        b {
          font-weight: 500;
        }
      }
      a {
        color: inherit;
        text-decoration: none !important;
        outline: 0;
        &.cnt {
          &:hover, &:focus, &:active {
            text-decoration: underline !important;
          }
        }
      }
    }
  }

  .footer-main {
    background: $dark3;
    padding: 80px 0;

    .footer-main-links {
      display: flex;
      padding-bottom: 30px;
      border-bottom: 1px solid $gray7;
      .footer-logo {
        flex: 1;
        max-width: 60%;

        img {
          width: auto;
          height: 42px;
        }
      }

      .footer-links {
        flex: 0;
        min-width: fit-content;

        .nav-item {
          align-self: center;

          .nav-link {
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            color: $white;
            padding-left: 28px;
            padding-right: 28px;

            &.active,
            &:hover,
            &:focus,
            &:active {
              color: $main;
            }
          }
        }
      }
    }

    .footer-main-creds {
      display: flex;
      margin-top: 50px;
      .credits-wrap {
        flex: 1;
      }
      .credits {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: $gray5;
        flex: 1;
        margin-bottom: 0;
      }
      .prcplcy-wrap {
        flex: 1;
        display: flex;
        justify-content: center;
        gap: 45px;
      }
      .prcplcy {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: $gray5;
        flex: 0;
        margin-bottom: 0;
        min-width: 110px;
        min-width: fit-content;
        text-decoration: none;
        outline: 0;
        cursor: pointer;
      }
    }
  }
}

.products-section {
  .container-fluid {
    padding-left: 20vw;
  }
  .sec-subttl {
    margin-bottom: 40px;
  }
  .product-cards-carousel-wrap {
    margin: 0;
    .product-cards-carousel {
      .slick-list {
        .slick-slide {
          margin-right: 16px;
        }
      }
    }
    .product-card {
      width: 100%;
    }
  }
}


.applications-section {
  .sec-ttl {
    margin-bottom: 80px;
  }
  .applications-cards-wrap {    
    .applications-cards-flex {
      display: flex;
      flex-wrap: wrap;
      .applications-card {
        width: calc(33% - 16px);
        margin: 0 8px 16px;
      }
    }
  }
}

.brand-section {
  padding-top: 80px;
  .sec-ttl {
    font-weight: 400;
    font-size: 56px;
    line-height: 68px;
    max-width: 1020px;
  }
  .sec-subttl {
    font-weight: 300;
    font-size: 24px;
    line-height: 38px;
    margin-top: 40px;
    color: $dark1;
    max-width: 1020px;
  }
  .para {
    font-weight: 300;
    font-size: 40px;
    line-height: 56px;
    margin-bottom: 40px;
    max-width: 1000px;
  }
  .brand-section-img {
    position: relative;
    width: 100%;
    height: 540px;
    max-width: 1000px;
    margin-top: 56px;
    margin-bottom: 80px;
    img {
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
  .brand-card-wrap {
    flex-wrap: wrap;
    gap: 16px;
    display: flex;
    .brand-card {
      margin: 0;
      width: 492px;
    }
  }
}

.formula-section {
  background-color: $light4;
  .sec-ttl {
    margin-bottom: 40px;
  }
  .formula-section-img {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 0;
    overflow: hidden;
    height: 632px;
    margin-top: 0px;
    img {
      position: absolute;
      width: 100%;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }
  .formula-cards-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    .formula-card {
      width: calc(50% - 16px);
      margin: 8px;
    }
  }
}

.submit-bttn {
  font-weight: 500;
  font-size: 14px;
  line-height: 64px;
  letter-spacing: 2.25px;
  text-transform: uppercase;
  color: $white;
  height: 64px;
  width: auto;
  margin: 0;
  padding: 0;
  text-align: center;
  border-radius: 0;
}

.custom-input {
  height: 48px;
  width: 100%;
  background: transparent;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: $white;
  border: 0;
  border-bottom: 1px solid;
  border-radius: 0;
  border-color: $gray5;
  box-shadow: none;
  outline: 0;
  &::placeholder {
    font: inherit;
    color: $light5;
  }
  &:hover, &:focus, &:active, &.active {
    box-shadow: none;
    outline: 0;
    background: transparent;
    color: $white;
  }
  &:focus {
    border-color: $main;
  }
}

.customSelect button.dropdown-toggle ,
.custom-input2 {
  height: 56px;
  width: 100%;
  background: $white;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: $dark1;
  border: 1px solid $light6;
  border-radius: 0;
  box-shadow: none;
  outline: 0;
  &textarea {
    height: auto;
  }
  &::placeholder {
    font: inherit;
    color: $gray5;
  }
  &:hover, &:focus, &:active, &.active {
    box-shadow: none;
    outline: 0;
    background: $white;
    color: inherit;
  }
  &:focus {
    border-color: $main;
  }
  &.custom-select2 {
    color: inherit;
    background-image: url("../assets/icons/dropdown-select.svg");
    background-repeat: no-repeat;
    background-position: 95% center;
    background-size: 14px auto;
  }
}
.customSelect button.dropdown-toggle,
.customSelect button.dropdown-toggle:focus,
.customSelect button.dropdown-toggle:hover,
.customSelect button.dropdown-toggle:active {
  outline: 0 !important;
  background: $white !important;
  &::after {
    border: 0;
    background-image: url("../assets/icons/dropdown-select.svg");
    background-repeat: no-repeat;
    background-position: 95% center;
    background-size: 14px auto;
    width: 14px;
    height: 8px;
    margin-top: 5px;
    margin-right: 5px;
  }
}
.customSelect .dropdown-toggle .filter-option {
  display: flex;
}
.customSelect .dropdown-toggle .filter-option .filter-option-inner {
  align-self: center;
  width: 100%;
}

.customSelect button.dropdown-toggle[aria-expanded="false"] {
  border: 1px solid $light6;
}
.customSelect button.dropdown-toggle[aria-expanded="true"] {
  border-color: $main;
  box-shadow: none !important;
}

.quote-section {
  .sec-ttl {
    font-weight: 400;
    font-size: 56px;
    line-height: 68px;
    margin: 0;
  }
  .p {
    color: $gray4;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    margin-top: 24px;
    margin-bottom: 0;
    max-width: 736px;
  }
  .quote-form-wrap {
    display: block;
    background: $dark6;
    margin-top: 56px;
    padding: 32px 34px;
    .quote-form {
      .custom-input {
        width: calc(100% - 60px);
        margin: 0 auto;
      }
      .submit-bttn {
        width: 246px;
        background-color: $main;
        &:hover, &:focus, &:active {
          background-color: $main;
          color: $white;
          box-shadow: none;
          outline: 0;
        }
      }
    }
  }
}

.homepage {
  .bttn-hero {
    line-height: 47px;
  }
  .specific-section {
    // .sec-ttl-wrap {
    //   max-width: 336px;
    //   margin-right: 40px;
    //   .p {
    //     font-style: normal;
    //     font-weight: 300;
    //     font-size: 18px;
    //     line-height: 156%;
    //     color: #000000;
    //   }
    // }
  }
}


.specific-section {
  background-color: $light4;
  .sec-ttl-wrap {
    display: block;
    // max-width: 314px;
    // margin-right: 125px;
    max-width: 336px;
    margin-right: 40px;
    .p {
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 156%;
    }
  }
  .sec-ttl {
    font-weight: 400;
    font-size: 56px;
    line-height: 68px;    
  }
  .iti {
    width: 100%;
  }
  .specific-form-wrap {
    .specific-form {
      textarea {
        height: auto;
        padding: 16px 20px;
        &::placeholder {
          line-height: normal;
        }
      }
      .submit-bttn {
        width: 191px;
        background-color: $main;
        margin-top: 10px;
        &:hover, &:focus, &:active {
          background-color: $main;
          color: $white;
          box-shadow: none;
          outline: 0;
        }
      }
    }
  }
}


.productDetails-hero-section {
  background-color: $light3;
  padding-top: 160px;
  padding-bottom: 136px;
  min-height: 92vh;
  min-height: 860px;
  .productDetails-hero-section-bg {
    position: absolute;
    display: block;
    height: 711px;
    width: 486px;
    top: 136px;
    left: auto;
    right: 0;
    bottom: auto;
    z-index: 0;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
  .sec-ttl2 {
    margin-bottom: 40px;
    line-height: 160%;
  }
}

.productDetailsCarousel {
  position: absolute;
  height: 100%;
  width: 100%;
  .carousel-indicators {
    margin-bottom: 30px;
    li {
      width: 64px;
      height: 8px;
      margin-right: 8px;
      margin-left: 8px;
      border-radius: 20px;
      border: 0;
    }
  }
  .carousel-inner {
    position: absolute;
    height: 100%;
    width: 100%;
    .carousel-item {
      position: absolute;
      height: 100%;
      width: 100%;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
      }
    }
  }
}

.applications-page {
  .productDetails-hero-section {
    padding-bottom: 300px;
  }
}

.details-block-wrap {
  .details-block {
    display: block;
    padding: 10px 0;
    border-top: 1px solid $gray2;
    margin-top: 40px;
    .des, .dtls {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;    
      color: $gray6;
      margin-bottom: 0;
    }
    .pr-5v {
      padding-right: 5vw;
    }
    .des {
      font-size: 14px;
      line-height: 24px;
      text-transform: uppercase;
      margin-bottom: 12px;
    }
    .num {
      font-weight: 800;
      font-size: 40px;
      line-height: 56px;
      color: $dark1;
      margin-bottom: 10px;
    }
  }
  .details-block-img {
    position: relative;
    height: 390px;
    width: 100%;
    img {
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
}

.productDetails-table-section {
  .product-table-wrap {
    margin-top: 40px;
    .custom-table-ttl {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      text-transform: capitalize;
      text-align: center;
      color: $tableHead;
      margin-bottom: 15px;
    }
  }
}

.productDetails-products-section {
  .sec-ttl2 {
    margin-bottom: 40px;
  }
}


.applicationDetails-products-section {
  .sec-ttl2 {
    margin-bottom: 40px;
  }
  .product-card {
    width: 100%;
    margin-bottom: 16px;
  }
}

.content {
  display: none;
}
.loadmore-bttn {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 64px;
  letter-spacing: 2.25px;
  text-transform: uppercase;
  color: $white;
  height: 64px;
  width: 246px;
  margin: 40px auto 0;
  padding: 0;
  text-align: center;
  border-radius: 0;
  border: 0;
  background-color: $main;
  &:hover, &:focus, &:active {
    background-color: $main;
    color: $white;
    box-shadow: none;
    outline: 0;
    border: 0;
  }
  &.noContent {
    color: $white;
    pointer-events: none;
  }
}


.about-hero-section {
  padding-top: 0;
  padding-bottom: 0;
  // border-bottom: 1px solid $gray2;
  background-image: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), url('../assets/about/banner.png');
  .bttn-hero {
    line-height: 50px;
  }
  .sec-ttl {
    font-weight: 700;
    font-size: 64px;
    line-height: 120%;
    letter-spacing: -0.02em;
  }
  .sec-subttl {
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    text-transform: uppercase;    
    color: $gray6;
    margin-top: 10px;
  }
  .scrollDown {
    margin-bottom: 20px;
  }
  .sec-para {
    margin-top: 22px;
  }
  .bttn-hero {
    margin-top: 100px;
  }
}


.established-section {
  padding-top: 80px;
  .para {
    font-weight: 300;
    font-size: 32px;
    line-height: 125%;
    color: $dark1;
    margin-bottom: 0;
    +.para {
      margin-top: 48px;
    }
  }
  .details-block-wrap {
    display: block;
    max-width: 100%;
    margin-left: auto;
    .details-block {
      margin-top: 96px;
      .dtls {
        +.dtls {
          margin-top: 24px;
        }
      }
    }
    .details-block-img {
      display: block;
      margin-top: 80px;
      height: 540px;
    }
  }
  .custom-container {
    .row {
      max-width: 1000px;
    }
  }
}

.certifications-section {
  .sec-subttl {
    margin-top: 20px;
    margin-bottom: 56px;
  }
  .brand-card-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    .brand-card {
      margin: 0;
      min-width: 492px;
    }
  }
}


.contact-section {
  padding-top: 168px;
  padding-bottom: 107px;
  .sec-ttl {
    font-weight: 800;
    font-size: 80px;
    line-height: 120%;
    margin: 0;
  }
  .sec-subttl {
    font-weight: 300;
    font-size: 32px;
    line-height: 125%;
    color: #F1F1F1;
    margin-top: 24px;
    max-width: 555px;
  }
  .contact-form-wrap {
    display: block;
    margin-top: 80px;
    .contact-form {
      .iti {
        width: 100%;
      }
      .custom-input {
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        color: $white;
        &::placeholder {
          font: inherit;
          font-weight: 300;
          color: $gray6;
        }
      }
      .form-group {
        margin-bottom: 68px;
      }
      .submit-bttn {
        display: block;
        background-color: $main;
        color: $white;
        height: 126px;
        width: 126px;
        border-radius: 100%;
        margin: 62px auto 0;
        border: 0;
        &:hover, &:focus, &:active {
          background-color: $main;
          color: $white;
          box-shadow: none;
          outline: 0;
          border: 0;
        }
      }
    }
  }
}


.scrollDown {
  .scrollDownButton {
    position: relative;
    display: block;
    width: 210px;
    height: 56px;
    border: 1px solid $gray6;
    border-radius: 40px;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    text-transform: uppercase;
    color: $gray6;
    padding: 20px 33px 20px 74px;
    text-decoration: none !important;
    cursor: pointer;
    &::before, &:after {
      position: absolute;
      content: '';
      display: block;
      background: url('../assets/icons/scroll-mouse.svg') no-repeat center;
      height: 24px;
      width: 24px;
      top: 50%;
      left: 33px;
      transform: translateY(-50%);
      z-index: 1;
    }
    &::after {
      background-image: url('../assets/icons/scoll-button.svg');
      top: 46%;
      animation: sdb05 1.2s infinite;
    }
  }
}

@keyframes sdb05 {
  0% {
    transform: translate(0, -15px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, -10px);
    opacity: 0;
  }
}


.form-wrap {
  .alert {
    margin: 0;
    max-width: 560px;
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    top: 85px;
    z-index: 11;
  }
}

.applicationDetails-products-section .product-card .prod-info {}

.other-applications-section {
  padding-top: 0;
  padding-bottom: 0;
  .sec-ttl2 {
    padding-bottom: 36px;
    border-bottom: 1px solid $gray6;
  }
  .other-applications-wrap {
    position: relative;
    max-width: 662px;
    width: 100%;
    height: 600px;
    margin-right: auto;

    height: 670px;
    overflow: hidden;
    position: relative;
    img {
      // position: absolute;
      // height: 100%;
      // width: 100%;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      // z-index: 1;
    }
  }
}

.client-logo-wrap-outer {
  animation: slidertop 33s linear infinite alternate-reverse;
  -webkit-animation: slidertop 33s linear infinite alternate-reverse;
  height: calc(100vh - 200px);
  &:hover {
    animation-play-state: paused;
  }
  .client-logo-wrap {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      max-width: calc(33% - 10px);
      gap: 16px;
      margin-top: -60px;
      &.ulList2 {
        margin-top: 0;
      }
      li {
        list-style: none;
        width: 100%;
        text-align: center;
        float: left;
        margin: 0;
        transition: all 0.2s linear 0s;
        filter: grayscale(100%);
        -webkit-transition: all 0.2s linear 0s;
        -moz-transition: all 0.2s linear 0s;
        -ms-transition: all 0.2s linear 0s;
        -o-transition: all 0.2s linear 0s;
        &:hover {
          filter: grayscale(0%);
        }
        img {
          height: 320px;
          max-width: 210px;
          object-fit: cover;
          width: 100%;
        }
      }
    }    
  }
}

.ty-section {
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  .thankyou-wrap {
    display: block;
    max-width: 725px;
    .thankyou-msg-wrap {
      display: flex;
      padding: 30px 25px;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      border: 1px solid $light8;
      max-width: 100%;
      .thankyou-msg-img {
        align-self: center;
        img {
          height: 66px;
          width: 66px;
          margin-right: 20px;
        }
      }
      .thankyou-msg-txt {
        align-self: center;
        .ttl {
          font-weight: 600;
          font-size: 28px;
          line-height: 143%;
          text-transform: uppercase;
          color: $green;
          margin-bottom: 8px;
        }
        .p {
          font-weight: 400;
          font-size: 18px;
          line-height: 156%;
          color: $dark1;
          margin-bottom: 0;
        }
      }
    }
    .bttn-hero {
      display: block;
      line-height: 56px;
      width: 225px;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 2.25px;
      text-transform: uppercase;
      margin: 80px auto 0;
    }
  }
}


@media (min-width: 1600px) {
  footer {
    .footer-secondary {
      .container-fluid {
        padding-left: 5vw;
        padding-right: 5vw;
      }
    }
  }
}

@media (max-width: 1600px) and (min-width: 1200px) {

  /* Common */
  .sec-ttl, .sec-ttl2 {
    font-size: 42px;
  }
  .sec-subttl {
    font-size: 36px;
    line-height: 130%;
  }
  .details-block-wrap {
    .details-block .num {
      font-size: 34px;
    }
  }
  .specific-section {
    .sec-ttl {
      font-size: 42px;
      line-height: 56px;
    }
  }
  .common-section.pattern::before {
    width: 420px;
    height: 409px;
  }

  .established-section {
    .para {
      font-size: 28px;
    }
  }

  /* Footer */
  footer {
    .footer-secondary {
      padding-top: 160px;
      .footer-ttl {
        font-size: 98px;
        span {
          top: 32px;
          width: 72px;
          height: 72px;
          right: -120px;
        }
      }
      .getInTouchCard {
        .p {
          font-size: 22px;
        }
      }
    }
  }

  /* Home, About, Contact */
  .hero-section {
    height: 100vh;
    min-height: 100vh;
    background-size: 100vw 100%;
    background-size: cover;
    .custom-container {
      padding-top: 60px;
    }
    .sec-ttl {
      font-size: 48px;
      line-height: 64px;
      max-width: 820px;
    }
  }
  .brand-section {
    .sec-ttl {
      font-size: 42px;
      line-height: 52px;
    }
    .sec-subttl {
      font-size: 20px;
      line-height: 36px;
    }
    .para {
      font-size: 32px;
      line-height: 42px;
    }
  }
  .about-hero-section {
    .sec-para {
      margin-top: 18px;
    }
    .bttn-hero {
      margin-top: 48px;
    }
  }
  .products-section {
    .container-fluid {
      padding-left: 12vw;
    }
  }

  .applications-card {
    padding: 20px 25px 20px;
    .applications-card-img {
      height: 150px;
      width: 160px;
    }
    .applications-card-box {
      .p {
        font-size: 15px;
        line-height: 22px;
      }
      .applications-card-ttl {
        font-size: 20px;
        line-height: 26px;
      }
    }
    .lnk {
      margin-top: 5px;
    }
  }

  .ordersDelivered-section .value {
    font-size: 160px;
    line-height: 100%;
  }

  /* Products Pages */
  .productDetailsCarousel {
    .carousel-indicators {
      li {
        width: 52px;
        height: 5px;
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
  .productDetails-hero-section {
    min-height: 750px;
    padding-bottom: 100px;
    .productDetails-hero-section-bg {
      height: 560px;
      width: 380px;
      top: 120px;
    }
  }

  /* Applications Pages */
  .applications-page {
    .productDetails-hero-section {
      padding-bottom: 100px;
    }
  }

}

@media (max-width: 1199.98px) {

  /* Common */
  .common-section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .custom-container {
    max-width: 960px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .sec-ttl {
    font-size: 38px;
    line-height: 48px;
  }
  .sec-ttl2 {
    font-size: 38px;
  }
  .sec-subttl {
    font-size: 24px;
    line-height: 32px;
  }
  .details-block-wrap {
    .details-block {
      margin-right: 50px;
       .dtls {
        font-size: 14px;
        line-height: 20px;
      }
      .num {
        font-size: 28px;
      }
      .pr-5v {
        padding-right: 0;
        text-align: justify;
      }
    }
  }
  .common-section.pattern::before {
    width: 320px;
    height: 312px;
  }
  .custom-input,
  .custom-input2 {
    height: 48px;
    font-size: 14px;
    line-height: 24px;
  }
  .submit-bttn {
    height: 48px;
    line-height: 48px;
  }
  .custom-table.table {
    thead {
      th {
        padding: 20px 30px 20px;
        font-size: 18px;
      }
    }
    tbody {
      tr {
        td {
          padding: 15px 30px;
          font-size: 14px;
        }
      }
    }
  }
  .owl-carousel.product-cards-carousel .owl-stage-outer, 
  .owl-carousel.other-product-cards-carousel .owl-stage-outer {
    max-height: 400px;
  }
  .product-card {
    width: 300px;
    height: 385px;
    padding: 20px 15px;
    .catg {
      letter-spacing: 0.14em;
      font-size: 11px;
      font-weight: 500;
    }
    .ttl {
      font-size: 14px;
      font-weight: 500;
    }
    .prod-img {
      width: 151px;
      height: 200px;
      margin: 30px auto 20px;
    }
    .prod-info {
      margin-top: 36px;
    }
  }
  .loadmore-bttn {
    height: 48px;
    line-height: 48px;
    margin-top: 15px;
  }
  

  /* Footer */
  footer {
    .footer-secondary {
      padding-top: 100px;
      .footer-ttl {
        font-size: 72px;
        margin-bottom: 60px;
        span {
          width: 72px;
          height: 72px;
          top: 10px;
          right: -100px;
        }
      }
      .getInTouchCard {
        padding: 30px 20px;
        .ttl {
          margin-bottom: 30px;
          font-size: 16px;
        }
        .p {
          font-size: 18px;
        }
      }
    }
    .footer-main {
      padding: 40px 0;
      .footer-main-links {
        .footer-links {
          .nav-item {
            .nav-link {
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 1px;
            }
          }
        }
      }
    }
  }
  .owl-carousel {
    .owl-nav {
      right: 20px;
    }
  }


  /* Home, About, Contact */
  .hero-section {
    height: 100vh;
    min-height: 100vh;
    background-size: 100vw 100%;
    background-size: cover;
    .custom-container {
      padding-top: 60px;
    }
    .sec-ttl {
      font-size: 48px;
      line-height: 64px;
      max-width: 820px;
    }
  }
  .about-hero-section {
    .sec-para {
      margin-top: 18px;
    }
    .bttn-hero {
      margin-top: 48px;
    }
  }
  .contact-section {
    .contact-form-wrap {
      .contact-form {
        .custom-input {
          height: 48px;
          font-size: 14px;
          line-height: 24px;
        }
        .form-group {
          margin-bottom: 48px;
        }
      }
    }
  }

  .brand-section {
    .sec-ttl {
      font-size: 36px;
      line-height: 42px;
    }
    .sec-subttl {
      margin-top: 30px;
      font-size: 18px;
      line-height: 24px;
    }
    .brand-section-img {
      height: 480px;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    .brand-section .para {
      margin-bottom: 30px;
      font-size: 24px;
      line-height: 36px;
    }
  }

  .established-section {
    .para {
      font-size: 20px;
    }
    .details-block-wrap {
      .details-block-img {
        height: 420px;
      }
    }
  }

  .specific-section {
    .sec-ttl {
      font-size: 38px;
      line-height: 48px;
    }
    .form-group {
      margin-bottom: 0;
    }
    .sec-ttl-wrap {
      // max-width: 275px;
      // margin-right: 50px;
      // display: block;
    }
    &.other-applications-section {
      padding: 0;
    }
  }

  /* Products Pages */
  .productDetailsCarousel {
    .carousel-indicators {
      li {
        width: 52px;
        height: 5px;
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
  .productDetails-hero-section {
    min-height: 95vh;
    padding-bottom: 80px;
    padding-top: 120px;
    .productDetails-hero-section-bg {
      height: 48vw;
      width: 33vw;
      top: 120px;
    }
  }
  .products-section {
    .container-fluid {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  /* Applications Pages */
  .applications-page {
    .productDetails-hero-section {
      padding-bottom: 80px;
    }
  }

}

@media (max-width: 991px) {

  /* Common */
  .common-section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .custom-container {
    max-width: 720px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .sec-ttl2 {
    font-size: 28px;
    line-height: 36px;
  }
  .details-block-wrap {
    .details-block {
      margin-top: 20px;
      .num {
        font-size: 18px;
        margin-bottom: 0;
        line-height: 24px;
      }
    }
  }
  .custom-table.table {
    thead {
      th {
        padding: 10px 15px;
        font-size: 16px;
        min-width: 150px;
      }
    }
    tbody {
      tr {
        td {
          padding: 7px 15px;
          font-size: 13px;
        }
      }
    }
  }

  /* Homepage, about, contact */
  .contact-section {
    padding-top: 120px;
    min-height: 100vh;
    .sec-ttl {
      font-size: 52px;
      font-weight: 500;
    }
    .sec-subttl {
      margin-top: 15px;
      font-size: 24px;
    }
  }
  .about-hero-section.hero-section {
    height: auto;
    min-height: 90vh;
  }


  .formula-section {
    .formula-section-img {
      height: 560px;
      margin-bottom: 30px;
      img {
        width: auto;
        height: 100%;
      }
    }
    .formula-cards-wrap {
      gap: 16px;
      .formula-card {
        width: calc(50% - 8px);
        margin: 0;
        padding: 20px 15px;
      }
    }
  }
  .established-section {
    .details-block-wrap {
      .details-block-img {
        height: 360px;
        margin-top: 40px;
      }
    }
  }
  .specific-section {
    .sec-ttl {
      font-size: 28px;
      line-height: 36px;
    }
    .sec-ttl-wrap {
      max-width: 100%;
      margin-right: 0;
      .p {
        font-size: 16px;
        font-weight: 300;
        line-height: 140%;
        margin-bottom: 24px;
        padding-right: 15%;
      }
    }
  }
  .other-applications-section {
    .sec-ttl2 {
      padding-bottom: 24px;
    }
  }

  /* Products Pages */
  .productDetails-hero-section {
    padding-top: 120px;
    min-height: unset;
    .sec-ttl2 {
      margin-bottom: 20px;
    }
  }
  .productDetails-table-section {
    .product-table-wrap {
      margin-top: 20px;
    }
  }

}

@media (max-width: 767px) and (min-width: 576px) {
  .homepage {
    .brand-section {
      .sec-ttl {
        max-width: 100%;
        font-size: 22px;
        line-height: 150%;
      }
      .sec-subttl {
        max-width: 100%;
        margin-top: 20px;
        font-size: 16px;
        line-height: 145%;
      }
      .brand-section-img {
        height: 320px;
        max-width: 100%;
        margin-top: 30px;
        margin-bottom: 50px;
      }
      .para {
        max-width: 100%;
        margin-bottom: 20px;
        font-size: 22px;
        line-height: 150%;
      }
    }
    .applications-card {
      height: auto;
      padding: 20px 15px;
      .applications-card-img {
        height: 110px;
        width: 118px;
      }
      .applications-card-box {
        max-width: unset;
        padding-top: 110px;
        .applications-card-ttl {
          font-size: 18px;
          font-weight: 500;
          line-height: 22px;
        }
        .p {
          font-size: 14px;
          line-height: 20px;
        }
      }
      .lnk {
        margin-top: 0;
        height: 32px;
        opacity: 1;
      }
    }
  }
  .homepage, .about-page {
    .hero-section {
      .sec-ttl {
        max-width: 80%;
        font-size: 36px;
        line-height: 48px;
      }
      .sec-para {
        max-width: 80%;
        margin-top: 15px;
        font-size: 18px;
        line-height: 24px;
      }
      .bttn-hero {
        margin-top: 36px;
      }
    }
  }
}

@media (max-width: 767px) {


  /* Common */
  .common-section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .custom-container {
    max-width: 560px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .sec-ttl2 {
    font-size: 28px;
    line-height: 36px;
  }
  .details-block-wrap {
    .details-block {
      margin-right: 0;
    }
  }
  .specific-section {
    .sec-ttl-wrap {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 30px;
    }
    .form-group {
      margin-bottom: 10px;
    }
    &.other-applications-section {
      padding: 60px 0 0;
      .sec-ttl2 {
        padding-bottom: 7px;
        margin-bottom: 30px;
        display: inline-block;
      }
    }
  }
  .owl-carousel {
    .owl-dots {
      max-width: calc(100% - 20vw);
    }
    .owl-nav {
      right: 5px;
    }
  }

  /* Homepage, about, contact */
  .contact-section {
    padding-top: 120px; 
    .sec-ttl {
      font-size: 48px;
    }
    .sec-subttl {
      margin-top: 20px;
      font-size: 20px;
    }
  }

  .ordersDelivered-section {
    padding: 80px 0;
    background-size: cover;
    .value {
      margin-bottom: 80px;
      font-size: 70px;
      line-height: 100%;
    }
    .values-blk-wrapper {
      .values-blk {
        .value-ttl {
          font-size: 18px;
          font-weight: 400;
          line-height: 100%;
        }
        .value-number {
          font-size: 42px;
        }
      }
    }
  }

  .applications-section {
    .applications-cards-wrap {
      .applications-cards-flex {
        gap: 16px;
        .applications-card {
          width: calc(50% - 16px);
          margin: 0;
        }
      }      
    }
  }
  

  /* Products Pages */
  .productDetails-hero-section {
    padding-top: 100px;
    padding-bottom: 0;
    .productDetails-hero-section-bg {
      position: relative;
      top: 0;
      left: 50%;
      height: 500px;
      width: 340px;
      transform: translateX(-50%);
      margin-bottom: 30px;
    }
  }
  .products-section {
    .container-fluid {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  /* Applications page */
  .applications-page {
    .productDetails-hero-section {
      padding-bottom: 0;
    }
  }


  /* Footer */
  footer {
    .footer-secondary {
      padding-top: 40px;
      padding-bottom: 0;
      .footer-ttl {
        margin-bottom: 40px;
        font-size: 48px;
        span {
          width: 52px;
          height: 52px;
          top: 4px;
          right: -80px;
        }
      }
      .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
      }
      .getInTouchCardCol {
        margin-bottom: 15px;
      }
    }
    .footer-main {
      .footer-main-links {
        .footer-links {
          .nav {
            gap: 15px;
            .nav-item {
              .nav-link {
                letter-spacing: 0;
                font-size: 12px;
                padding: 7px 0;
              }
            }
          }
        }
      }
      .footer-main-creds {
        .credits {
          font-size: 10px;
          font-weight: 400;
        }
        .prcplcy-wrap {
          gap: 15px;
          justify-content: flex-end;
        }
      }
    }
  }
}
 

@media (max-width: 575.98px) {

  /* common css start */
  .custom-container {
    max-width: unset;
    max-width: unset;
    padding-left: 15px;
    padding-right: 15px;
  }

  .bttn-hero {
    width: 250px;
    height: 48px;
    margin-top: 30px;
    font-size: 16px;
    line-height: 24px;
  }

  .submit-bttn,
  .loadmore-bttn {
    display: block; 
    width: 100%;
    max-width: 360px;
    height: 48px;
    line-height: 48px;
    margin-left: auto;
    margin-right: auto;
  }

  .common-section {
    padding: 40px 0;
  }

  .sec-ttl, .sec-ttl2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
  }

  .sec-subttl {
    margin-top: 20px;
    font-size: 18px;
    line-height: 28px;
  }

  .custom-input, .custom-input2 {
    font-size: 14px;
    height: 44px;
  }

  .details-block-wrap {
    .details-block {
      margin-top: 20px;
      .des {
        text-transform: uppercase;
        margin-bottom: 12px;
        font-size: 14px;
        line-height: 24px;
      }
      .dtls {
        font-size: 14px;
        line-height: 22px;
      }
      .num {
        margin-bottom: 10px;
        font-size: 24px;
        line-height: 30px;
        + .dtls {
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
  }

  .custom-table {
    margin-bottom: 5px;
    &.table {
      thead {
        th {
          min-width: 220px;
          padding: 10px 15px 10px;
          font-size: 16px;
          line-height: 18px;
          &:nth-child(1) {
            min-width: 140px;
          }
        }
      }
      tbody {
        tr {
          td {
            min-width: unset;
            padding: 10px 15px 10px;
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
  }
  .productDetailsCarousel {
    .carousel-indicators {
      margin-bottom: 10px;
      li {
        width: 40px;
        height: 4px;
      }
    }
  }

  .owl-carousel {
    .owl-dots {
      max-width: calc(100% - 22vw);
      .owl-dot {
        height: 3px;
        &.active {
          top: -2px;
          height: 6px;
        }
      }
    }
    .owl-nav {
      bottom: -6px;
      right: 4px;
      .owl-prev {
        margin-right: 5px;
      }
      .owl-prev, .owl-next {
        svg {
          height: 24px;
          width: 24px;  
        }
      }
    }
  }

  /* common css end */

  /* Homepage, about, contact */
  
  .contact-section {
    padding-top: 120px; 
    .sec-ttl {
      font-size: 36px;
    }
    .sec-subttl {
      margin-top: 10px;
      font-size: 16px;
    }
    .contact-form-wrap {
      margin-top: 20px;
      .contact-form {
        .custom-input {
          height: 44px;
        }
        .form-group {
          margin-bottom: 20px;
        }
        .submit-bttn {
          margin-top: 60px;
          border-radius: 0;
          line-height: 48px;
          height: 48px;
          width: 100%;
          max-width: 360px;
        }
      }
    }
  }
  .about-hero-section .bttn-hero {
    line-height: 36px;
  }
  .homepage .hero-section .bttn-hero {
    line-height: 36px;
  }
  .about-hero-section.hero-section {
    height: auto;
    min-height: unset;
  }

  .established-section {
    .para {
      font-size: 16px;
    }
    .details-block-wrap {
      .details-block-img {
        height: 220px;
        margin-top: 40px;
      }
    }
  }

  /* sections css start */
  .hero-section {
    padding: 150px 0;
    height: auto;
    min-height: unset;
    .sec-ttl {
      font-size: 26px;
      font-weight: 700;
      line-height: 36px;
    }
    .sec-para {
      margin-top: 24px;
      font-size: 16px;
      line-height: 24px;

    }
  }

  .brand-section {
    .sec-ttl {
      font-size: 24px;
      font-weight: 400;
      line-height: 28px;
    }
    .sec-subttl {
      margin-top: 20px;
      font-size: 16px;
      line-height: 26px;
    }
    .brand-section-img {
      width: 100%;
      height: 220px;
      max-width: 100%;
      margin-top: 20px;
      margin-bottom: 36px;
      position: relative;
    }
    .para {
      max-width: 100%;
      margin-bottom: 20px;
      font-size: 22px;
      line-height: 120%;
    }
    .brand-card-wrap {
      flex-wrap: wrap;
      .brand-card {
        width: 100%;
      }
    }
  }

  .specific-section {
    .sec-ttl-wrap {
      .p {
        padding-right: 0;
        font-size: 14px;
        line-height: 136%;
      }
    }
  }

  .products-section {
    .container-fluid {
      padding-left: 15px;
      padding-right: 15px;
    }
    .sec-subttl {
      margin-top: 10px;
      margin-bottom: 30px;
    }
  }

  .formula-section {
    .sec-ttl {
      margin-bottom: 0;
    }
    .formula-section-img {
      margin-top: 20px;
      height: 420px;
      margin-top: 20px;
      margin-bottom: 30px;
    }
    .formula-cards-wrap {
      gap: 5px;
      .formula-card {
        width: calc(50% - 4px);
      }
    }
  }

  .applications-section {
    .sec-ttl {
      margin-bottom: 12px;
    }
    .applications-cards-wrap {
      .applications-cards-flex {
        .applications-card {
          width: 100%;
          margin: 0 0 16px;
        }
      }
    }
  }

  .quote-section {
    .sec-ttl {
      font-size: 30px;
      line-height: 40px;
    }
    .p {
      margin-top: 15px;
      font-size: 14px;
      line-height: 20px;      
    }
    .quote-form-wrap {
      margin-top: 30px;
      padding: 10px 20px 20px;
      .quote-form {
        .custom-input {
          width: 100%;
          margin: 0 auto 20px;
        }
        .submit-bttn {
          width: 100%;
          margin: 20px auto 0;
        }
      }
    }
  }

  .ordersDelivered-section {
    padding-top: 120px;
    padding-bottom: 120px;
    background-size: cover;
    .sec-ttl {
      padding-bottom: 4px;
      font-size: 18px;
    }
    .value {
      margin-bottom: 48px;
      font-size: 60px;
      line-height: 100%;
      sup {
        padding-left: 3%;
        font-size: 65%;
        top: 2px;
      }
    }
    .values-blk-wrapper {
      .values-blk {
        .value-ttl {
          font-size: 14px;
          padding-bottom: 4px;
          margin-bottom: 0;
        }
        .value-number {
          font-size: 28px;
          line-height: 150%;
        }
      }
    }
  }

  .specific-section {
    .sec-ttl-wrap {
      max-width: unset;
      margin-right: 0;
    }
    .sec-ttl {
      font-size: 24px;
      line-height: 120%;
    }
    .specific-form-wrap {
      .specific-form {
        textarea {
          height: auto;
          padding: 5px 10px;
        }
        .submit-bttn {
          margin-top: 10px;
          width: 100%;
        }
      }
    }
  }

  .specific-section {
    &.other-applications-section {
      padding: 30px 0 0;
      .sec-ttl2 {
        margin-bottom: 0;
      }
      .other-applications-wrap {
        height: 500px;
        margin: 0;
        .client-logo-wrap-outer {
          height: 420px;
          animation: 16s linear infinite alternate-reverse slidertop;
          &:hover {
            animation-play-state: paused;
          }
          .client-logo-wrap {
            gap: 0;
            ul {
              max-width: calc(50% - 8px);
              gap: 5px;
              margin: 0;
              li {
                height: 200px;
                max-width: 131px;
                img {
                  height: 100%;
                  width: auto;
                }
              }
            }
            .ulList1 {
              justify-content: flex-end;
              transform: translateY(-60px);
            }
            .ulList3 {
              display: none;
            }
          }
        }
      }
    }
  }

  /* Product Details */
  .productDetails-hero-section {
    padding-top: 70px;
    padding-bottom: 0;
    .productDetails-hero-section-bg {
      height: 400px;
      width: 280px;
      margin-bottom: 40px;
    }
    .sec-ttl2 {
      margin-bottom: 20px;
    }
  }

  .productDetails-table-section {
    .product-table-wrap {
      margin-top: 20px;
    }
  }
  .productDetails-products-section {
    .product-cards-carousel-wrap {
      .owl-dots {
        // display: none;
      }
    }
  }

  .productDetails-products-section .sec-ttl2, 
  .applicationDetails-products-section .sec-ttl2 {
    margin-bottom: 20px;
  }

  .other-applications-section {
    .other-applications-wrap {
      max-width: 100%;
      width: 100%;
      height: 310px;
      margin: 20px 0;
    }    
  }

  .product-card {
    height: 350px;
    .catg {
      padding-right: 15px;
    }
    .prod-info {
      margin-top: 20px;
    }
  }

  /* Application pages */
  .applicationDetails-products-section {
    .loadmore-bttn {
      margin-top: 0;
    }
    .content:nth-of-type(2n + 1) {
      padding-right: 2px;
      padding-left: 4px;
    }
    .content:nth-of-type(2n) {
      padding-left: 2px;
      padding-right: 4px;
    }
  }

  /* sections css end */


  /* Footer css start */
  footer {
    .container {
      padding-left: 15px;
      padding-right: 15px;
    }
    .footer-secondary {
      padding-top: 40px;
      padding-bottom: 0;
      .footer-ttl {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 40px;
        span {
          width: 36px;
          height: 36px;
          top: 4px;
          right: -50px;
          background-size: 42%;
        }
      }
      .getInTouchCardCol {
        margin-bottom: 16px;
      }
      .getInTouchCard {
        padding: 20px 15px;
        .ttl {
          margin-bottom: 30px;
          font-size: 16px;
          line-height: 20px;
        }
        .p {
          font-size: 18px;
        }
      }
    }
    .footer-main {
      padding: 20px 0;
      .footer-main-links {
        display: block;
        .footer-logo {
          display: block;
          width: 100%;
          max-width: 100%;
          height: auto;
          img {
            display: block;
            text-align: center;
            height: 31px;
            margin: 0 auto 20px;
          }
        }
        .footer-links {
          .nav {
            justify-content: space-between;
            flex-wrap: wrap;
            .nav-item {
              .nav-link {
                padding: 0;
                font-size: 14px;
              }
            }
          }
        }
      }
      .footer-main-creds {
        margin-top: 40px;
        p {
          margin: 0;
        }
        .prcplcy-wrap {
          gap: 10px;
          .prcplcy {
            font-size: 11px;
            font-weight: 400;
          }
        }        
      }
    }
  }
  /* Footer css end */

  /* Thankyou page */
  .ty-section {
    .thankyou-wrap {
      .thankyou-msg-wrap {
        flex-direction: column;
        padding: 20px 15px;
        .thankyou-msg-img {
          img {
            height: 40px;
            width: 40px;
            margin-right: 0;
            margin-bottom: 15px;
          }
        }
        .thankyou-msg-txt {
          .ttl {
            text-align: center;
            font-size: 20px;
            line-height: 32px;
            max-width: 80%;
            margin-left: auto;
            margin-right: auto;
          }
          .p {
            text-align: center;
            font-size: 16px;
            line-height: 22px;
            max-width: 80%;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
      .bttn-hero {
        margin-top: 56px;
        line-height: 40px;
      }
    }
  }

}

@media (max-width: 390px) and (min-width: 320px) {
  .product-card {
    height: 90vw;
    min-height: 325px;
    .prod-img {
      width: 100%;
      height: 52vw;
      margin: 20px auto 20px;
    }
    .prod-info {
      margin-top: 2vw;
    }
  }
}

.tel-input.is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") !important;
  border-color: #198754 !important;
  &:focus {
    box-shadow: 0 0 0 .25rem #19875440 !important;
  }
}
.tel-input.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
  border-color: #dc3545 !important;
  &:focus {
    box-shadow: 0 0 0 .25rem #dc354540 !important;
  }
}