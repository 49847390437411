// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../node_modules/bootstrap/scss/functions";

// 2. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "../../node_modules/bootstrap/scss/variables";

// Default variable overrides
$body-bg: #F2F2F2;
$body-color: #161A20;
$primary: #049989;
$light: #FFFFFF;
$dark: #161A20;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);

// Import all of Bootstrap's CSS
@import "../../node_modules/intl-tel-input/build/css/intlTelInput.min.css";
@import "../../node_modules/slick-carousel/slick/slick.css";
@import "../../node_modules/slick-carousel/slick/slick-theme.css";

@import "../../node_modules/bootstrap/scss/bootstrap.scss";

@import './header';
@import './cards';
@import './main';