@import './colors';

.product-card {
  display: block;
  padding: 35px 25px;
  background: $white;
  border: 1px solid $gray2;
  width: 323px;
  height: 500px;
  cursor: pointer;
  text-decoration: none !important;
  .catg {
    position: relative;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.24em;
    text-transform: uppercase;
    color: $dark1;
    span {
      position: absolute;
      left: auto;
      right: 0;
      img {
        object-fit: cover;
      }
    }
  }
  .ttl {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: $dark1;
    margin-top: 12px;
  }
  .prod-img {
    position: relative;
    width: 187px;
    height: 250px;
    margin: 50px auto 20px;
    img {
      position: absolute;
      top: 0;
      left: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: translateX(-50%);
    }
  }
  .prod-info {
    position: relative;
    display: block;
    margin-top: 56px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $dark1;
    .prod-info-icon {
      position: absolute;
      top: -2px;
      left: auto;
      right: 0;
      &.prod-info-icon-normal {
        opacity: 1;
      }
      &.prod-info-icon-active {
        opacity: 0;
      }
    }
  }
  &:hover, &:focus, &:active {
    background: $light4;
    .prod-info-icon {
      &.prod-info-icon-normal {
        opacity: 0;
      }
      &.prod-info-icon-active {
        opacity: 1;
      }
    }
  }
}


.applications-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border: 1px solid $gray4;
  background: $white;
  padding: 40px 32px;
  height: 390px;
  z-index: 1;
  .applications-card-img {
    position: absolute;
    display: block;
    height: 209px;
    width: 209px;
    top: 0;
    left: auto;
    right: 16px;
    z-index: -1;
    img {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 1;
    }
  }
  .applications-card-box {
    max-width: 228px;
    .applications-card-ttl {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.02em;
      text-transform: capitalize;
      color: $dark4;
      margin-bottom: 0;
    }
    .p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $gray3;
      margin-top: 8px;
      margin-bottom: 0;
    }    
  }
  .lnk {
    position: relative;
    display: block;
    margin-top: 12px;
    height: 0;
    width: 100%;
    line-height: 32px;
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    color: $mainText;
    opacity: 0;
    &::after {
      position: absolute;
      content: '';
      display: block;
      height: 32px;
      width: 32px;
      background: url('../assets/icons/prod-title-icon-active.svg') no-repeat center;
      top: 0;
      left: auto;
      right: 0;
      z-index: 1;
    }
  }
  &:hover {
    border-color: $dark5;
    background: $light4;
    .lnk {
      height: 32px;
      opacity: 1;
    }
  }
}

.brand-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: flex-start;
  padding: 36px 36px;
  // border: 1px solid #8A8C8F;
  border: 1px solid #f2f9fc;
  min-height: 324px;
  background-color: $white;
  max-width: 492px;
  text-decoration: none;
  // cursor: pointer;
  cursor: default;
  background-color: $white;
  text-decoration: none;
  .brand-card-bg {
    position: absolute;
    height: 136px;
    width: 136px;
    top: auto;
    left: auto;
    right: 20px;
    bottom: 27px;
    z-index: 1;
    img {
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
  .ttl {
    position: relative;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    margin-bottom: 0;
    color: $dark1;
    text-decoration: none;
    width: 100%;

    color: $mainText;
    &::before {
      position: absolute;
      // content: '';
      display: block;
      background: url('../assets/icons/prod-title-icon-active.svg') no-repeat center;
      height: 48px;
      width: 48px;
      top: 0;
      left: auto;
      right: -6px;
    }
  }
  .p {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: $dark1;
    margin-bottom: 0;
    max-width: 230px;
  }
  &:hover, &:focus, &:active {
    background-color: $light4;
    text-decoration: none;
    .ttl {
      color: $mainText;
      &::before {
        position: absolute;
        // content: '';
        display: block;
        background: url('../assets/icons/prod-title-icon-active.svg') no-repeat center;
        height: 48px;
        width: 48px;
        top: 0;
        left: auto;
        right: -6px;
      }
    }
  }
}


.formula-card {
  background: $white;
  padding: 40px 24px;
  border: 1px solid $light5;
  .formula-card-icon {
    position: relative;
    display: block;
    width: 42px;
    height: 42px;
    margin-bottom: 32px;
    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
  .ttl {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: $dark1;
    margin-top: 0;
    margin-bottom: 0;
  }
  .p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: $dark1;
    margin-bottom: 0;
    margin-top: 12px;
  }
  &:hover, &:focus, &:active {
    background-color: $light4;
    border-color: $main;;
    .ttl {
      color: $main;
    }
  }
}

@media (max-width: 1199.98px) {
  .brand-card {
    min-height: 250px;
    min-width: calc(50% - 10px) !important;
    max-width: calc(50% - 10px) !important;
    padding: 20px 15px;
    width: calc(50% - 10px);
    .brand-card-bg {
      height: 110px;
      width: 110px;
      right: 15px;
      bottom: 20px;
    }
    .ttl {
      font-size: 18px;
      line-height: 22px;
    }
    .p {
      font-size: 18px;
      line-height: 24px;
      max-width: 60%;
    }
  }
  .applications-card {
    height: 420px;
    padding-bottom: 15px;
    .applications-card-img {
      height: 140px;
      width: 150px;
      top: 10px;
    }
  }
}

@media (max-width: 991px) {
  .formula-card {
    .formula-card-icon {
      width: 38px;
      height: 38px;
      margin-bottom: 20px;
    }
    .ttl {
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
    }
    .p {
      font-size: 14px;
    }
  }
}

@media (max-width: 767px) and (min-width: 576px) {
  .brand-card {
    .brand-card-bg {
      height: 70px;
      width: 70px;
      bottom: 20px;
      right: 10px;
    }
    .ttl {
      font-size: 16px;
      line-height: 22px;
    }
    .p {
      max-width: 72%;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

@media (max-width: 575.98px) {
  .brand-card {
    min-height: 180px;
    width: 100%;
    max-width: 100% !important;
    min-width: 100% !important; 
    .ttl {
      font-size: 16px;
      line-height: 20px;
      &::before {
        height: 36px !important;
        width: 48px !important;
        background-size: 48% !important;
      }
    }
    .p {
      font-size: 14px;
      line-height: 18px;
    }
    .brand-card-bg {
      height: 92px;
      width: 92px;
      right: 5px;
      bottom: 5px;
    }
  }

  .product-card {
    width: 100%;
    height: 360px;
    padding: 20px 15px;
    .catg {
      font-size: 10px;
      line-height: 12px;
      margin-bottom: 0;
    }
    .ttl {
      margin-top: 8px;
      font-size: 14px;
      line-height: 18px;
    }
    .prod-img {
      width: 142px;
      height: 190px;
      margin: 15px auto;
    }
    .prod-info {
      margin-top: 48px;
      font-size: 11px;
      line-height: 17px;
      .prod-info-icon {
        position: absolute;
        top: -2px;
        left: auto;
        right: 0;
        img {
          height: 20px;
          width: 21px;
        }
        &.prod-info-icon-normal {
          opacity: 1;
        }
        &.prod-info-icon-active {
          opacity: 0;
        }
      }
    }
  }    

  .formula-card {
    padding: 20px 15px;
    .formula-card-icon {
      width: 32px;
      height: 32px;
      margin-bottom: 20px;
    }
    .ttl {
      font-size: 18px;
      line-height: 22px;
    }
    .p {
      margin-top: 15px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .applications-card {
    height: 240px;
    padding: 10px 15px;
    .applications-card-img {
      height: 124px;
      width: 124px;
    }
    .applications-card-box {
      .applications-card-ttl {
        font-size: 18px;
        line-height: 22px;
      }
      .p {
        font-size: 14px;
        line-height: 18px;
      }
    }
    .lnk {
      margin-top: 8px;
      line-height: 20px;
      height: 20px;
      opacity: 1;
    }
    &:hover {
      .lnk {
        height: 20px;
      }
    }
  }

}