@import './colors';

.scroll-down {
  .header-nav {
    // transform: translateY(0px);
    transform: translateY(80px);
    background: $white !important;
    filter: drop-shadow(0 -10px 10px rgb(170, 170, 170));
    .navbar-nav .nav-item .nav-link {
      color: $dark1 !important;
    }
    .dropdown-menu.show {
      // display: none;
    }
  }
}
.scroll-up {
  .header-nav {
    transform: translateY(80px);
    background: $white !important;
    filter: drop-shadow(0 -10px 10px rgb(170, 170, 170));
    .navbar-nav .nav-item .nav-link {
      color: $dark1 !important;
    }
  }
}

.contact-page, .homepage {
  .header-nav .navbar-nav .nav-item .nav-link {
    color: $white;
  }
}

.prod-page, .applications-page {
  .header-nav {
    background: $white !important;
  }
}

.header-nav {
  height: 80px;
  max-width: 100vw;
  position: fixed;
  top: -80px;
  left: 0;
  background: none !important;
  z-index: 9999;
  width: 100vw;
  transition: all .3s ease-in-out;
  transform: translateY(80px);
  box-shadow: none;
  filter: none;
  .navbar-brand {
    max-height: 50px;
    max-width: 180px;
    padding: 0;
    margin: 0;
    text-decoration: none;
    .header-logo {
      height: 48px;
      width: auto;
    }
  }
  .custom-toggler.navbar-toggler {
    border-color: $main;
    padding: 0 4px;
    border-radius: 4px;
    .navbar-toggler-icon {
      height: 24px;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='20' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }
  }
  .dropdown {
    .dropdown-toggle {
      &::after {
        display: none;
        width: 100%;
        border-width: 1px;
      }
      &:hover, &:focus, &:active, &.active, &.show {
        display: block;        
      }
    }
    .dropdown-menu {
      background: $white;
      padding: 12px 0;
      box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
      border-radius: 0;
      border: 0;
      top: calc(100% + 22px);
      margin: 0;
      min-height: unset;
      &.submenu.level2 {
        min-height: 264px;
      }
      .dropdown-item {
        position: relative;
        background: transparent;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: $dark1;
        height: 56px;
        margin-bottom: 0;
        border-bottom: 1px solid $light5;
        padding: 18px 40px 18px 0;
        span {
          position: absolute;
          display: block;
          background: none;
          width: 24px;
          height: 24px;
          top: 50%;
          left: auto;
          right: 0;
          transform: translateY(-50%);
          z-index: 1;
        }
        &:hover, &:focus, &:active, &.active {
          background: transparent;
          color: $main;
          span {
            background-image: url('../assets/icons/prod-title-icon-active.svg');
            background: url('../assets/icons/prod-title-icon-active.svg') no-repeat center;
          }
        }
      }
      > li {
        // position: relative;
        background-color: transparent;
        padding: 12px 48px 12px;
        &:hover {
          background-color: inherit;
          > .submenu {
            display: block;
          }
        }
        .submenu {
          display: none;
          position: absolute;
          left: 100%;
          top: 0;
        }
        .submenu-left{ 
          right:100%; left:auto;
        }
      }
    }
  }
  .navbar-nav {
    .nav-item {
      position: relative;
      .nav-link {
        position: relative;
        margin-right: 0;
        margin-left: 36px;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.4em;
        text-transform: uppercase;
        color: $dark1;
        z-index: 9;
        &::after {
          position: absolute;
          content: '';
          display: none;
          background-color: $main;
          height: 1px;
          width: 100%;
          top: auto;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1;
        }
        &:hover, &:focus, &:active, &.active {
          color: $main;
          &::after {
            display: block;
          }
        }
      }
      &:first-child {
        .nav-link {
          margin-left: 0;
        }
      }
    }
  }
  #getInstantQuote {
    font-weight: 800;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0;
    color: $main;
    &::after {
      display: block;
    }
  }
}

/* Responsive */

@media (min-width: 1600px) {
  .header-nav .dropdown .dropdown-menu > li .submenu.level2 {
    min-height: 264px;
  }
}


@media (max-width: 1600px) and (min-width: 1200px) {
  .header-nav {
    .dropdown {
      .dropdown-menu {
        > li {
          padding: 0px 20px;
        }
      }
    }
  }
}

@media (max-width: 1199.98px) {
  .header-nav {
    .navbar-brand {
      .header-logo {
        height: 42px;
      }
    }
    .navbar-nav {
      .nav-item {
        .nav-link {
          letter-spacing: 1px;
          margin-left: 20px;
        }
      }
    }
    .dropdown {
      .dropdown-menu {
        > li {
          padding: 0px 15px;
          &:last-child {
            .dropdown-item {
              border-bottom: 0;
            }
          }
        }
        .dropdown-item {
          letter-spacing: 0.15em;
          height: 40px;
          padding: 10px 30px 10px 0px;
          font-size: 10px;
          span {
            background-size: 75% !important;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .dropdown-menu .dropdown-menu {
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    margin-bottom: .5rem;
  }
  .contact-page, .homepage {
    .header-nav .navbar-nav .nav-item .nav-link {
      color: $dark1;
    }
  }
  .header-nav {
    height: auto;
    background: $white !important;
    padding: 10px 40px;
    #main_nav {
      margin: 10px 0;
    }
    .navbar-nav {
      .nav-item {
        .nav-link {
          margin-left: 0;
          padding: 10px 15px;
          &::after {
            display: none !important;
          }
          &.show {
            background-color: $light4;
          }
        }
      }
    }
    .dropdown {
      .dropdown-menu {
        position: relative;
        padding: 10px 20px 20px;
        max-width: calc(100% - 4px);
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 10px;
        border-radius: 8px;
        overflow: hidden;
        .level1 {
          border-bottom: 1px solid $light4;
          font-size: 9px;
          span {
            background: url('../assets/icons/prod-title-icon-active.svg') no-repeat center;
            right: 15px;
          }
        }
        &.submenu.level2 {
          display: block !important;
          min-height: unset !important;
          position: static;
          box-shadow: none;
          transform: none;
          margin-bottom: 0;
          max-width: 100%;
          padding: 0;
          border-radius: 4px;
          font-size: 9px;
          li {
            padding: 0;
            .dropdown-item {
              font-size: 9px;
              border-bottom: 1px solid #e1f0f7;
              background-color: $light4;
              padding: 5px 15px;
              span {
                background: url('../assets/icons/prod-title-icon-active.svg') no-repeat center;
                right: 15px;
              }
            }
            &:last-child {
              .dropdown-item {
                border-bottom: 0;
              }
            }
          }
        }  
        .dropdown-item {
          font-size: 11px;
          height: auto;
          line-height: 20px;
        }
        > li {
          padding: 0;
          .dropdown-item {
            padding: 8px 0;
            font-size: 11px;
            font-weight: 700;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  
}

@media (max-width: 575.98px) {
  .header-nav {
    height: auto;
    padding-left: 0;
    padding-right: 0;
    .navbar-brand {
      margin-left: 15px;
      .header-logo {
        height: 36px;
      }
    }
    .custom-toggler {
      margin-right: 15px;
    }
    .navbar-nav {
      .nav-item {
      }
    }
    .dropdown {
      .dropdown-menu {
        max-width: calc(100% - 30px);
        padding: 5px 15px 15px;
        margin-bottom: 5px;
        .dropdown-item {
          font-size: 9px;
          line-height: 18px;
        }
      }
    }
    #getInstantQuote {
      letter-spacing: 0.1em;
      font-size: 10px;
      font-weight: 500;
      &::after {
        display: none;
      }
    }
  }
}
